import { FC } from "react";
import styles from "./popup.module.scss";
interface IPopup {
  Children: any;
  handleClose?: (e?: React.MouseEvent<HTMLElement>) => void;
  popData?: string | any;
  message?: string;
  setModelOpenClose?: any;
}

const Popup: FC<IPopup> = ({
  Children,
  handleClose,
  popData,
  message,
  setModelOpenClose,
}) => {
  return (
    <>
      <div className={styles.popup} onClick={() => handleClose && handleClose()}>
        <div >
          <Children
            popData={popData}
            message={message}
            setModelOpenClose={setModelOpenClose}
            handleClose={handleClose}
          />
        </div>
      </div>
    </>
  );
};

export default Popup;
