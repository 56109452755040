// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup_popup__MOsyV {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  overflow-y: auto;
  margin: auto;
  z-index: 2;
}`, "",{"version":3,"sources":["webpack://./src/components/common/popup/popup.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,MAAA;EACA,OAAA;EACA,8BAAA;EACA,gBAAA;EACA,YAAA;EACA,UAAA;AACF","sourcesContent":[".popup {\n  position: absolute;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  min-height: 100%;\n  top: 0;\n  left: 0;\n  background: rgba(0, 0, 0, 0.8);\n  overflow-y: auto;\n  margin: auto;\n  z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `popup_popup__MOsyV`
};
export default ___CSS_LOADER_EXPORT___;
