import createAsyncThunkForSlice from "../../../utils/utils";
import {
  GET_ALL_MEDICAL_CENTER,
  ADD_MEDICAL_CENTER_TYPE,
  GET_ALL_MEDICAL_CENTER_BY_ID,
  UPDATE_MEDICAL_CENTER_BY_ID,
  DELETE_MEDICAL_CENTER_BY_ID,
  ACTIVE_MEDICAL_CENTER_BY_ID,
  ALL_MEDICAL_CENTER_USER,
} from "../../../constants/asyncActionsType";
import {
  getAllMc,
  addMedicalCenter,
  getMcById,
  updateMCById,
  deleteMCById,
  activeMCById,
  allMedicalCenterUsers,
} from "./medicalCenterCrud";

export const getAllMedicalCenter = createAsyncThunkForSlice(
  GET_ALL_MEDICAL_CENTER,
  getAllMc
);

export const createMedicalCenter = createAsyncThunkForSlice(
  ADD_MEDICAL_CENTER_TYPE,
  addMedicalCenter,
  {
    isToast: true,
  }
);

export const getAllMedicalCenterById = createAsyncThunkForSlice(
  GET_ALL_MEDICAL_CENTER_BY_ID,
  getMcById
);

export const updateMedicalCenterById = createAsyncThunkForSlice(
  UPDATE_MEDICAL_CENTER_BY_ID,
  updateMCById,
  {
    isToast: true,
  }
);

export const deleteMedicalCenterById = createAsyncThunkForSlice(
  DELETE_MEDICAL_CENTER_BY_ID,
  deleteMCById,
  {
    isToast: true,
  }
);

export const activeMedicalCenterById = createAsyncThunkForSlice(
  ACTIVE_MEDICAL_CENTER_BY_ID,
  activeMCById,
  {
    isToast: true,
  }
);

export const getAllMedicalCenterUser = createAsyncThunkForSlice(
  ALL_MEDICAL_CENTER_USER,
  allMedicalCenterUsers
);
