import { FC } from "react";
import { Outlet } from "react-router-dom";

interface IMedicalCenterLayout {}

const MedicalCenterLayout: FC<IMedicalCenterLayout> = (props) => {
  return <Outlet />;
};

export default MedicalCenterLayout;
