import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Button from '../../../components/common/button/Button'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { IProactMedicalCenterForm } from '../../../interfaces/interfaces'
import {
  createMedicalCenters,
  getAllProactMedicalCenter,
} from '../../../redux/features/mobile-app-config/proactMobileAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { dataURI, isDataUri } from '../../../utils/utils'
import MedicalCenterCard from './medical-center-card/MedicalCenterCard'
import styles from './proactMedicalCenter.module.scss'
import Loader from '../../../components/common/spinner/Loader'
import { setMessage } from '../../../redux/features/toast/toastSlice'
import { failure, warning } from '../../../constants/data'
import { clearState } from '../../../redux/features/mobile-app-config/proactMobileAppSlice'

const ProactMedicalCenter: FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading, proactMedicalCenterData } = useAppSelector(
    (state) => state.proactMobileConfig
  )
  let arr = [
    { name: 'Medical Center 1', _id: 0 },
    { name: 'Medical Center 2', _id: 1 },
    { name: 'Medical Center 3', _id: 2 },
    { name: 'Medical Center 4', _id: 3 },
    { name: 'Medical Center 5', _id: 4 },
  ]
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<IProactMedicalCenterForm>()

  const onSubmit: SubmitHandler<IProactMedicalCenterForm> = async (
    data: any
  ) => {
    const payload = Object.values(data)
    const base64Array = await Promise.all(
      payload.map(async (item: any) => {
        if (isDataUri(item?.mc_image)) {
          return item
        } else {
          let file = item.mc_image[0]
          const base64DataUri = await dataURI(file)
          return {
            ...item,
            mc_image: base64DataUri,
          }
        }
      })
    )
    let valid = false
    let message = 'Please add at least one medical center with all detail'
    console.log('base64Array', base64Array)
    let finalPayload: any[] = []
    if (base64Array?.some((item) => item?.mc_name && item?.mc_image)) {
      finalPayload = base64Array?.map((item, index) => {
        if (item?.mc_name && item?.mc_image) {
          valid = true
          return item
        } else if (!item?.mc_name && item?.mc_image) {
          valid = false
          message = `Please provide a medical center name for ${arr[index].name}`
          return undefined
        } else if (item?.mc_name && !item?.mc_image) {
          valid = false
          message = `Please provide a medical image name for ${arr[index].name}`
          return undefined
        }
      })
    } else {
      finalPayload = []
      valid = false
      message = 'Please add at least one medical center with all detail'
    }
    if (finalPayload && finalPayload.length > 0 && valid) {
      const reqPayload = finalPayload?.filter((item) => item !== undefined)
      console.log('reqPayload', reqPayload)
      dispatch(createMedicalCenters(requestGenerator(reqPayload)))
    } else {
      dispatch(
        setMessage({
          message,
          type: failure,
        })
      )
    }
  }

  useEffect(() => {
    dispatch(getAllProactMedicalCenter(requestGenerator({})))
  }, [dispatch])

  useEffect(() => {
    if (proactMedicalCenterData.length > 0) {
      const arr = proactMedicalCenterData?.map((item: any, number: number) => {
        return {
          mc_name: item?.mc_name,
          mc_image: item?.mc_image,
        }
      })
      let formdata = { ...arr }
      reset(formdata)
    } else {
      const emptyFileList = new DataTransfer().files
      const obj = { mc_name: '', mc_image: emptyFileList }
      const formData = arr?.map((item: any) => {
        return obj
      })
      let formDataMap = { ...formData }
      reset(formDataMap)
    }
  }, [reset, proactMedicalCenterData])
  return (
    <>
      {isLoading && <Loader />}

      <div className={styles.meedicalCenterContainer}>
        <p className={styles.title}>PROACT Medical Center</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.cardsContainer}>
            {arr &&
              arr.length > 0 &&
              arr.map((item: any, index: any) => {
                return (
                  <MedicalCenterCard
                    item={item}
                    key={index}
                    index={index}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    watch={watch}
                  />
                )
              })}
          </div>
          <div className={styles.buttonContainer}>
            <Button title="Submit" type="submit" />
            <Button
              title="Reset"
              type="button"
              customClass={styles.resetBtn}
              handleClick={() => {
                dispatch(clearState())
              }}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default ProactMedicalCenter
