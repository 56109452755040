import axios from 'axios'
import {
  CREATE_DOCTORS_URL,
  CREATE_MEDICAL_CENTER,
  CREATE_NEWS_URL,
  GET_MEDICAL_CENTER,
  GET_NEWS_URL,
} from '../../../config/config'
import { IAPIPayload } from '../../../interfaces/apiInterface'
import { GET_DOCTORS_URL } from '../../../config/config'

export const createNews = (data: IAPIPayload) => {
  return axios.post(CREATE_NEWS_URL, data)
}
export const getNews = () => {
  return axios.post(GET_NEWS_URL)
}

export const createMedicalCenter = (data: IAPIPayload) => {
  return axios.post(CREATE_MEDICAL_CENTER, data)
}

export const getMedicalCenter = () => {
  return axios.post(GET_MEDICAL_CENTER)
}

export const createProactDoctorConfig = (data: IAPIPayload) => {
  return axios.post(CREATE_DOCTORS_URL, data)
}

export const getProactDoctorConfig = () => {
  return axios.post(GET_DOCTORS_URL)
}
