import {
  ADD_DATABASE_URI_TYPE,
  AWS_CONNECTION_TYPE,
  GET_ALL_MEDICAL_CENTER_TYPE,
  UPDATE_CONFIG_DATA_TYPE,
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import {
  addDataBaseUriData,
  awsConnectionData,
  getAllMcUtility,
  updateConfig,
} from "./medicalCenterUtilityCrud";

// get dropdown value
export const getAllMedicalCenterUtility = createAsyncThunkForSlice(
  GET_ALL_MEDICAL_CENTER_TYPE,
  getAllMcUtility,
  {
    isToast: true,
  }
);

// add database uri
export const addDataBaseUri = createAsyncThunkForSlice(
  ADD_DATABASE_URI_TYPE,
  addDataBaseUriData,
  {
    isToast: true,
  }
);

// awsConnection
export const awsConnectionAction = createAsyncThunkForSlice(
  AWS_CONNECTION_TYPE,
  awsConnectionData,
  {
    isToast: true,
  }
);

// update config
export const updateConfigData = createAsyncThunkForSlice(
  UPDATE_CONFIG_DATA_TYPE,
  updateConfig,
  {
    isToast: true,
  }
);
