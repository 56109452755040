import { createSlice } from '@reduxjs/toolkit'
import { ILoginState } from '../../../interfaces/apiInterface'
import {
  userForgotpassword,
  userLogin,
  userLogout,
  userResendOtp,
  userResetPassword,
  userVerifyOtp,
} from './loginAsynActions'

const initialState: ILoginState = {
  loading: false,
  userData: {},
  encryptionKey: '',
  isLoggedin: false,
  otpRequestId: '',
  isOtpVerified: false,
  resetPWDToken: '',
  otpAttempt: 0,
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setEncryptionKey: (state, action) => {
      state.encryptionKey = action.payload
    },
    // userLogout: (state) => {
    //   state.isLoggedin = false
    //   state.encryptionKey = ''
    //   state.userData = {}
    //   state.isLoggedin = false
    // },
    setOtpAttempts: (state, action) => {
      state.otpAttempt = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true
        state.userData = {}
        state.isLoggedin = false
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false
        state.userData = action.payload
        state.isLoggedin = true
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false
        state.userData = {}
        state.isLoggedin = false
      })
      .addCase(userForgotpassword.pending, (state) => {
        state.loading = true
        state.userData = {}
        state.otpRequestId = ''
        state.isLoggedin = false
      })
      .addCase(userForgotpassword.fulfilled, (state, action) => {
        state.loading = false
        state.userData = {}
        state.otpRequestId = action.payload
        state.isLoggedin = false
      })
      .addCase(userForgotpassword.rejected, (state, action) => {
        state.loading = false
        state.userData = {}
        state.otpRequestId = ''
        state.isLoggedin = false
      })
      .addCase(userVerifyOtp.pending, (state) => {
        state.loading = true
        state.userData = {}
        state.isOtpVerified = false
        state.isLoggedin = false
      })
      .addCase(userVerifyOtp.fulfilled, (state, action) => {
        state.loading = false
        state.userData = {}
        state.isOtpVerified = true
        state.resetPWDToken = action.payload.token
        state.isLoggedin = false
        state.otpAttempt = 0
      })
      .addCase(userVerifyOtp.rejected, (state, action) => {
        state.loading = false
        state.userData = {}
        state.isOtpVerified = false
        state.isLoggedin = false
        state.otpAttempt = state.otpAttempt + 1
      })
      .addCase(userResendOtp.pending, (state) => {
        state.loading = true
        state.userData = {}
        state.otpRequestId = ''
        state.isLoggedin = false
      })
      .addCase(userResendOtp.fulfilled, (state, action) => {
        state.loading = false
        state.userData = {}
        state.otpRequestId = action.payload
        state.isLoggedin = false
        state.otpAttempt = 0
      })
      .addCase(userResendOtp.rejected, (state, action) => {
        state.loading = false
        state.userData = {}
        state.otpRequestId = ''
        state.isLoggedin = false
      })
      .addCase(userResetPassword.pending, (state) => {
        state.loading = true
        state.userData = {}
        state.isLoggedin = false
      })
      .addCase(userResetPassword.fulfilled, (state, action) => {
        state.loading = false
        state.userData = {}
        state.isLoggedin = false
      })
      .addCase(userResetPassword.rejected, (state, action) => {
        state.loading = false
        state.userData = {}
        state.isLoggedin = false
      })

      .addCase(userLogout.pending, (state) => {
        state.loading = true
        state.userData = {}
        state.isLoggedin = false
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        state.loading = false
        state.userData = {}
        state.encryptionKey = ''
        state.isLoggedin = false
      })
      .addCase(userLogout.rejected, (state, action) => {
        state.loading = false
        state.userData = {}
        state.isLoggedin = false
      })

  },
})

export const { setEncryptionKey, setOtpAttempts } =
  loginSlice.actions

export default loginSlice.reducer
