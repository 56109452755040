export const colors = {
  grey1: "#BBBBBB",
  grey2: "#797979",
  grey3: "#353751",
  grey4: "#CDD4D8",
  blue1: "#3D96E7",
  black: "#000",
  black1: "#181818",
  white1: "#fff",
  green: "#00ff00",
  green1: "#02BF90",
  red1: "#B11313",
  red2: "#ff0000",
};
