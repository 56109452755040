// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_spinnerContainer__5FHqZ {
  justify-content: center;
  align-items: center;
  position: absolute;
  display: flex;
  min-height: 100%;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  margin: auto;
}
.loader_spinnerContainer__5FHqZ .loader_loadingSpinner__pGfH4 {
  width: 55px;
  height: 55px;
  border: 10px solid var(--white1);
  border-top: 10px solid var(--grey4);
  border-radius: 50%;
  animation: loader_spinner__iZot7 0.9s linear infinite;
}
@keyframes loader_spinner__iZot7 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/spinner/loader.module.scss"],"names":[],"mappings":"AA4BA;EACE,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,gBAAA;EACA,WAAA;EACA,UAAA;EACA,MAAA;EACA,OAAA;EACA,8BAAA;EACA,gBAAA;EACA,YAAA;AA3BF;AA4BE;EACE,WAAA;EACA,YAAA;EACA,gCAAA;EACA,mCAAA;EACA,kBAAA;EACA,qDAAA;AA1BJ;AA2BI;EACE;IACE,uBAAA;EAzBN;EA2BI;IACE,yBAAA;EAzBN;AACF","sourcesContent":["// .loader {\n//   border: 2px solid var(--white1);\n//   border-radius: 50%;\n//   border-top: 2px solid transparent;\n//   width: 25px;\n//   height: 25px;\n//   -webkit-animation: spin 2s linear infinite; /* Safari */\n//   animation: spin 2s linear infinite;\n//   margin: 0px auto;\n//   /* Safari */\n//   @-webkit-keyframes spin {\n//     0% {\n//       -webkit-transform: rotate(0deg);\n//     }\n//     100% {\n//       -webkit-transform: rotate(360deg);\n//     }\n//   }\n\n//   @keyframes spin {\n//     0% {\n//       transform: rotate(0deg);\n//     }\n//     100% {\n//       transform: rotate(360deg);\n//     }\n//   }\n// }\n.spinnerContainer {\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  display: flex;\n  min-height: 100%;\n  width: 100%;\n  z-index: 2;\n  top: 0;\n  left: 0;\n  background: rgba(0, 0, 0, 0.4);\n  overflow-y: auto;\n  margin: auto;\n  .loadingSpinner {\n    width: 55px;\n    height: 55px;\n    border: 10px solid var(--white1);\n    border-top: 10px solid var(--grey4);\n    border-radius: 50%;\n    animation: spinner 0.9s linear infinite;\n    @keyframes spinner {\n      0% {\n        transform: rotate(0deg);\n      }\n      100% {\n        transform: rotate(360deg);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerContainer": `loader_spinnerContainer__5FHqZ`,
	"loadingSpinner": `loader_loadingSpinner__pGfH4`,
	"spinner": `loader_spinner__iZot7`
};
export default ___CSS_LOADER_EXPORT___;
