import { combineReducers } from '@reduxjs/toolkit'
import toastReducer from '../features/toast/toastSlice'
import loginReducer from '../features/login/loginSlice'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import packageReducer from '../features/package/packageSlice'
import medicalCenterReducer from '../features/medical-center/medicalCenterSlice'
import proactMobileConfigSlice from '../features/mobile-app-config/proactMobileAppSlice'
import commonReducer from '../features/common/commonSlice'
import medicalCenterUtilityReducer from "../features/medical-center-utility/medicalCenterUtilitySlice"
const persistConfig = {
  storage,
  key: 'proact-user',
  whitelist: ['userData', 'encryptionKey', 'isLoggedin'],
}

const peristedLoginReducer = persistReducer(persistConfig, loginReducer)

const rootReducer: any = combineReducers({
  toast: toastReducer,
  login: peristedLoginReducer,
  common: commonReducer,
  package: packageReducer,
  medicalCenter: medicalCenterReducer,
  proactMobileConfig: proactMobileConfigSlice,
  medicalCenterUtility:medicalCenterUtilityReducer
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
