import styles from './mainLayout.module.scss'
import Header from './header/Header'
import SideBar from './sidebar/SideBar'
import { Outlet } from 'react-router-dom'

const MainLayout: React.FunctionComponent = () => {
  return (
    <div className={styles.mainContainer}>
      <SideBar />
      <div className={styles.headerChildrenContainer}>
        <Header />
        <Outlet />
      </div>
    </div>
  )
}

export default MainLayout
