import { createSlice } from '@reduxjs/toolkit';
import { IMedicalCenterState } from '../../../interfaces/apiInterface';
import {
  activeMedicalCenterById,
  createMedicalCenter,
  deleteMedicalCenterById,
  getAllMedicalCenter,
  getAllMedicalCenterById,
  getAllMedicalCenterUser,
  updateMedicalCenterById,
} from './medicalCenterAsynActions';

const initialState: IMedicalCenterState = {
  isLoading: false,
  mcLoading: false,
  medicalCenterData: [],
  medicalCenterByIdData: {},
  updatedMedicalCenterData: {},
  error: null,
  isStatusUpdated: true,
  createUserData: [],
};
export const medicalCenterSlice = createSlice({
  name: 'medicalCenter',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.error = '';
      state.medicalCenterByIdData = {};
    },
    clearMedicalListState: (state) => {
      state.medicalCenterData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMedicalCenter.pending, (state) => {
        state.mcLoading = true;
        state.isStatusUpdated = false;
      })
      .addCase(getAllMedicalCenter.fulfilled, (state, action) => {
        state.mcLoading = false;
        state.medicalCenterData =
          action.payload?.data?.length > 0 ? action.payload?.data : [];
        state.isStatusUpdated = false;
      })
      .addCase(getAllMedicalCenter.rejected, (state, error) => {
        state.mcLoading = false;
        state.medicalCenterData = [];
        state.error = error;
      })
      // create MC
      .addCase(createMedicalCenter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createMedicalCenter.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createMedicalCenter.rejected, (state, error) => {
        state.isLoading = false;
        state.error = error;
      });

    // getMCByID
    builder
      .addCase(getAllMedicalCenterById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllMedicalCenterById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.medicalCenterByIdData = action.payload;
      })
      .addCase(getAllMedicalCenterById.rejected, (state, error) => {
        state.isLoading = false;
        state.error = error;
      });

    // updateMCByID
    builder
      .addCase(updateMedicalCenterById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMedicalCenterById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updatedMedicalCenterData = action.payload;
      })
      .addCase(updateMedicalCenterById.rejected, (state, error) => {
        state.isLoading = false;
        state.error = error;
      });

    // deleteMCById
    builder
      .addCase(deleteMedicalCenterById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteMedicalCenterById.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteMedicalCenterById.rejected, (state, error) => {
        state.isLoading = false;
        state.error = error;
      });

    // activeMCById
    builder
      .addCase(activeMedicalCenterById.pending, (state) => {
        state.isLoading = true;
        state.isStatusUpdated = false;
      })
      .addCase(activeMedicalCenterById.fulfilled, (state) => {
        state.isLoading = false;
        state.isStatusUpdated = true;
      })
      .addCase(activeMedicalCenterById.rejected, (state, error) => {
        state.isLoading = false;
        state.error = error;
        state.isStatusUpdated = false;
      });

    // allMedicalCenterUsers
    builder
      .addCase(getAllMedicalCenterUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllMedicalCenterUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.createUserData = action.payload?.data;
      })
      .addCase(getAllMedicalCenterUser.rejected, (state, error) => {
        state.isLoading = false;
        state.error = error;
      });
  },
});

export const { clearState, clearMedicalListState } = medicalCenterSlice.actions;
export default medicalCenterSlice.reducer;
