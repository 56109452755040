import axios from "axios";
import {
  GETALLMC_URL,
  GETMCBYID_URL,
  UPDATEMC_URL,
  ADD_MEDICAL_CENTER_URL,
  DELETEMC_URL,
  ACTIVEMC_URL,
  ALLMCUSERS_URL,
} from "../../../config/config";
import { IAPIPayload } from "../../../interfaces/apiInterface";

export const getAllMc = (data: IAPIPayload) => {
  return axios.post(GETALLMC_URL, data);
};

export const getMcById = (data: IAPIPayload) => {
  return axios.post(GETMCBYID_URL, data);
};

export const updateMCById = (data: IAPIPayload) => {
  return axios.post(UPDATEMC_URL, data);
};

export const addMedicalCenter = (data: IAPIPayload) => {
  return axios.post(ADD_MEDICAL_CENTER_URL, data);
};

export const deleteMCById = (data: IAPIPayload) => {
  return axios.post(DELETEMC_URL, data);
};

export const activeMCById = (data: IAPIPayload) => {
  return axios.post(ACTIVEMC_URL, data);
};

export const allMedicalCenterUsers = (data: IAPIPayload) => {
  return axios.post(ALLMCUSERS_URL, data);
};
