// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_mainContainer__1gaBg {
  position: relative;
  margin: 0px;
  margin: 20px 20px 30px 0px;
}
.header_mainContainer__1gaBg .header_headerContainer__hJyru {
  background: var(--white2);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.header_mainContainer__1gaBg .header_headerContainer__hJyru .header_headerText__4lawV {
  font-weight: var(--font-semibold);
  font-size: var(--XXSmall);
  line-height: 14px;
}
.header_mainContainer__1gaBg .header_headerContainer__hJyru .header_profileContainer__FDFwN {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100px;
  justify-content: space-between;
  cursor: pointer;
}
.header_mainContainer__1gaBg .header_headerContainer__hJyru .header_profileContainer__FDFwN .header_title__7RGII {
  font-weight: var(--font-semibold);
  font-size: var(--XSmall);
  line-height: 23px;
  margin-right: 30px;
}
.header_mainContainer__1gaBg .header_headerContainer__hJyru .header_profileContainer__FDFwN .header_notificationImg__LNz3J {
  padding: 0 10px 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/main-layout/header/header.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,0BAAA;AACF;AAAE;EACE,yBAAA;EACA,kCAAA;UAAA,0BAAA;EACA,mDAAA;EACA,mBAAA;EACA,aAAA;EAEA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AACJ;AAAI;EACE,iCAAA;EACA,yBAAA;EACA,iBAAA;AAEN;AAAI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,8BAAA;EACA,eAAA;AAEN;AAAM;EACE,iCAAA;EACA,wBAAA;EACA,iBAAA;EACA,kBAAA;AAER;AAAM;EACE,sBAAA;AAER","sourcesContent":[".mainContainer {\n  position: relative;\n  margin: 0px;\n  margin: 20px 20px 30px 0px;\n  .headerContainer {\n    background: var(--white2);\n    backdrop-filter: blur(6px);\n    box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n    border-radius: 10px;\n    padding: 10px;\n    // margin: 20px 20px 30px 0px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    .headerText {\n      font-weight: var(--font-semibold);\n      font-size: var(--XXSmall);\n      line-height: 14px;\n    }\n    .profileContainer {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      width: 100px;\n      justify-content: space-between;\n      cursor: pointer;\n\n      .title {\n        font-weight: var(--font-semibold);\n        font-size: var(--XSmall);\n        line-height: 23px;\n        margin-right: 30px;\n      }\n      .notificationImg {\n        padding: 0 10px 0 20px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `header_mainContainer__1gaBg`,
	"headerContainer": `header_headerContainer__hJyru`,
	"headerText": `header_headerText__4lawV`,
	"profileContainer": `header_profileContainer__FDFwN`,
	"title": `header_title__7RGII`,
	"notificationImg": `header_notificationImg__LNz3J`
};
export default ___CSS_LOADER_EXPORT___;
