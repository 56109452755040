import {
  ADD_PACKAGE,
  DELETE_PACKAGE_BY_ID,
  EDIT_PACKAGE,
  GET_ALL_PACKAGE,
  GET_ALL_PACKAGE_MODULES,
  GET_PACKAGE_BY_ID,
  UPDATE_STATUS_BY_ID,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  getAllPackages,
  addPackages,
  editPackages,
  getAllPackageModules,
  getPackagesByIds,
  deletePackageByIds,
  updateStatusByIds,
} from '../package/packageCrud'

export const getAllPackage = createAsyncThunkForSlice(
  GET_ALL_PACKAGE,
  getAllPackages
)

export const getAllPackageModule = createAsyncThunkForSlice(
  GET_ALL_PACKAGE_MODULES,
  getAllPackageModules
)

export const addPackage = createAsyncThunkForSlice(ADD_PACKAGE, addPackages, {
  isToast: true,
})

export const editPackage = createAsyncThunkForSlice(
  EDIT_PACKAGE,
  editPackages,
  {
    isToast: true,
  }
)

export const getPackageById = createAsyncThunkForSlice(
  GET_PACKAGE_BY_ID,
  getPackagesByIds
)

export const deletePackageById = createAsyncThunkForSlice(
  DELETE_PACKAGE_BY_ID,
  deletePackageByIds,
  {
    isToast: true,
  }
)

export const updatePackageStatusById = createAsyncThunkForSlice(
  UPDATE_STATUS_BY_ID,
  updateStatusByIds,
  {
    isToast: true,
  }
)
