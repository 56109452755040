// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_viewLinkStyle__6iI\\+I {
  text-decoration: none;
  color: #0e26a3;
}

.table_actionIconStyle__XvtHN .table_iconStyle__l5jdd {
  cursor: pointer;
  margin-left: 10px;
  margin-top: 4px;
}
.table_actionIconStyle__XvtHN .table_editIconStyle__NILMP {
  margin-left: 10px;
  margin-top: 4px;
}

.table_view__uHlCB {
  color: #0e26a3;
  cursor: pointer;
  padding: 0;
}

.table_noReordFoundText__a73JU {
  text-align: center;
  color: var(--black1);
  font-size: var(--font-16);
  font-weight: var(--font-bold);
  padding: 12px 0px;
}

.table_toogleStyle__kEY-8 {
  display: flex;
  justify-content: center;
}

.table_expiryDate__cCf5b {
  padding-right: 25px;
}

.table_expiryDateRed__qkhs0 {
  padding-right: 25px;
  color: var(--red1);
}`, "",{"version":3,"sources":["webpack://./src/components/common/table/table.module.scss","webpack://./src/styles/mixin.scss"],"names":[],"mappings":"AACA;EACE,qBAAA;EACA,cAAA;AAAF;;AAGE;EACE,eAAA;EACA,iBAAA;EACA,eAAA;AAAJ;AAEE;EACE,iBAAA;EACA,eAAA;AAAJ;;AAGA;EACE,cAAA;EACA,eAAA;EACA,UAAA;AAAF;;AAGA;EACE,kBAAA;ECbA,oBDcc;ECbd,yBDa6B;ECZ7B,6BDY6C;EAC7C,iBAAA;AAEF;;AAAA;EACE,aAAA;EACA,uBAAA;AAGF;;AAAA;EACE,mBAAA;AAGF;;AADA;EACE,mBAAA;EACA,kBAAA;AAIF","sourcesContent":["@import '../../../styles/mixin.scss';\n.viewLinkStyle {\n  text-decoration: none;\n  color: #0e26a3;\n}\n.actionIconStyle {\n  .iconStyle {\n    cursor: pointer;\n    margin-left: 10px;\n    margin-top: 4px;\n  }\n  .editIconStyle {\n    margin-left: 10px;\n    margin-top: 4px;\n  }\n}\n.view {\n  color: #0e26a3;\n  cursor: pointer;\n  padding: 0;\n  // margin-right: 12px;\n}\n.noReordFoundText {\n  text-align: center;\n  @include font(var(--black1), var(--font-16), var(--font-bold));\n  padding: 12px 0px;\n}\n.toogleStyle {\n  display: flex;\n  justify-content: center;\n}\n\n.expiryDate {\n  padding-right: 25px;\n}\n.expiryDateRed {\n  padding-right: 25px;\n  color: var(--red1);\n}\n","@mixin pageWrapper($padding) {\n  background: var(--white2);\n  backdrop-filter: blur(6px);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: $padding;\n  margin: 20px 20px 30px 0px;\n}\n\n@mixin font($color, $size, $weight) {\n  color: $color;\n  font-size: $size;\n  font-weight: $weight;\n}\n\n@mixin flexCenter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@mixin flexVertical {\n  display: flex;\n  align-items: center;\n}\n\n@mixin responsive($width) {\n  @media (max-width: $width) {\n    @content;\n  }\n}\n\n@mixin animation($name) {\n  @keyframes #{$name} {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewLinkStyle": `table_viewLinkStyle__6iI+I`,
	"actionIconStyle": `table_actionIconStyle__XvtHN`,
	"iconStyle": `table_iconStyle__l5jdd`,
	"editIconStyle": `table_editIconStyle__NILMP`,
	"view": `table_view__uHlCB`,
	"noReordFoundText": `table_noReordFoundText__a73JU`,
	"toogleStyle": `table_toogleStyle__kEY-8`,
	"expiryDate": `table_expiryDate__cCf5b`,
	"expiryDateRed": `table_expiryDateRed__qkhs0`
};
export default ___CSS_LOADER_EXPORT___;
