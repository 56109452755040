// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs_tabData__cCd6a {
  display: flex;
  padding-left: 5px;
}
.tabs_tabData__cCd6a .tabs_activeTab__74ewH {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white1);
  font-size: var(--font-13);
  font-weight: var(--font-semibold);
  min-height: 42px;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 100%, 78% 0);
  background: var(--gredientColor);
  border-radius: 12px 50px 0 0px;
  padding: 0px 50px 0px 20px;
  margin-left: 12px;
}
.tabs_tabData__cCd6a .tabs_tabContent__ODX4h {
  display: flex;
  align-items: center;
  color: var(--black2);
  font-size: var(--font-13);
  font-weight: var(--font-normal);
  height: 38px;
  margin-right: 20px;
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/tabs/tabs.module.scss","webpack://./src/styles/mixin.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,iBAAA;AADF;AAEE;ECWA,aAAA;EACA,mBAAA;EACA,uBAAA;EARA,oBDHgB;ECIhB,yBDJ+B;ECK/B,iCDL+C;EAC7C,gBAAA;EAEA,4DAAA;EACA,gCAAA;EACA,8BAAA;EACA,0BAAA;EACA,iBAAA;AAIJ;AADE;ECKA,aAAA;EACA,mBAAA;EAbA,oBDSgB;ECRhB,yBDQ+B;ECP/B,+BDO+C;EAC7C,YAAA;EACA,kBAAA;EACA,aAAA;AAMJ","sourcesContent":["@import '../../../styles//mixin.scss';\n\n.tabData {\n  display: flex;\n  padding-left: 5px;\n  .activeTab {\n    @include flexCenter;\n    @include font(var(--white1), var(--font-13), var(--font-semibold));\n    min-height: 42px;\n    -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 95%, 75% 0);\n    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 100%, 78% 0);\n    background: var(--gredientColor);\n    border-radius: 12px 50px 0 0px;\n    padding: 0px 50px 0px 20px;\n    margin-left: 12px;\n  }\n\n  .tabContent {\n    @include flexVertical;\n    @include font(var(--black2), var(--font-13), var(--font-normal));\n    height: 38px;\n    margin-right: 20px;\n    padding: 20px;\n  }\n}\n","@mixin pageWrapper($padding) {\n  background: var(--white2);\n  backdrop-filter: blur(6px);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: $padding;\n  margin: 20px 20px 30px 0px;\n}\n\n@mixin font($color, $size, $weight) {\n  color: $color;\n  font-size: $size;\n  font-weight: $weight;\n}\n\n@mixin flexCenter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@mixin flexVertical {\n  display: flex;\n  align-items: center;\n}\n\n@mixin responsive($width) {\n  @media (max-width: $width) {\n    @content;\n  }\n}\n\n@mixin animation($name) {\n  @keyframes #{$name} {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabData": `tabs_tabData__cCd6a`,
	"activeTab": `tabs_activeTab__74ewH`,
	"tabContent": `tabs_tabContent__ODX4h`
};
export default ___CSS_LOADER_EXPORT___;
