import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../../components/common/button/Button'
import { doctorArray, failure } from '../../../constants/data'
import styles from './proactDoctor.module.scss'
import { proactDoctorValidations } from '../../../form-validators/proactDoctorConfigValidator'
import { IProactDoctorForm } from '../../../interfaces/interfaces'
import AttachFiles from '../../../components/common/attach-files/single-file/AttachSingleFile'
import { useAppDispatch, useAppSelector } from '../../../hooks/index'
import {
  DOCTOR_IMAGE,
  DOCTOR_NAME,
  DOCTOR_SPECIALITY,
} from '../../../constants/constant'
import { requestGenerator } from '../../../utils/payloadGenerator'
import {
  createDoctors,
  getDoctors,
} from '../../../redux/features/mobile-app-config/proactMobileAsyncActions'
import { dataURI, isDataUri } from '../../../utils/utils'
import { CREATE_DOCTOR_TYPE } from '../../../constants/asyncActionsType'
import Loader from '../../../components/common/spinner/Loader'
import { clearState } from '../../../redux/features/mobile-app-config/proactMobileAppSlice'
import { setMessage } from '../../../redux/features/toast/toastSlice'

const ProactDoctor: FC = () => {
  const dispatch = useAppDispatch()
  const { proactDoctorsData, isLoading } = useAppSelector(
    (state) => state.proactMobileConfig
  )

  console.log('base64Array>>')

  // get form data
  useEffect(() => {
    dispatch(getDoctors(requestGenerator({})))
  }, [dispatch])

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IProactDoctorForm>()
  const formData = watch() //watch for data

  //set default form data
  console.log('proactDoctorsData', proactDoctorsData)
  useEffect(() => {
    if (proactDoctorsData.length > 0) {
      const arr = proactDoctorsData?.map((item: any, number: number) => {
        return {
          dr_name: item?.dr_name,
          dr_image: item?.dr_image,
          dr_speciality: item?.dr_speciality,
        }
      })
      let formdata = { ...arr }
      reset(formdata)
    } else {
      const emptyFileList = new DataTransfer().files
      const obj = { dr_name: '', dr_speciality: '', dr_image: emptyFileList }
      const formData = doctorArray?.map((item: any) => {
        return obj
      })
      let formDataMap: any = { ...formData }
      reset(formDataMap)
    }
  }, [reset, proactDoctorsData])

  // onsubmit
  const onSubmit = async (data: IProactDoctorForm) => {
    const formArray = Object.values(data)

    const base64Array = await Promise.all(
      formArray.map(async (item: any) => {
        // console.log(file.name)
        if (isDataUri(item?.dr_image)) {
          return item
        } else {
          let file = item.dr_image[0]
          const base64DataUri = await dataURI(file)
          return {
            ...item,
            dr_image: base64DataUri,
          }
        }
      })
    )
    let valid = false
    let message = 'Please add at least one doctor with all details'
    let finalPayload: any[] = []
    if (
      base64Array?.some(
        (item) => item?.dr_name && item?.dr_image && item?.dr_speciality
      )
    ) {
      finalPayload = base64Array?.map((item, index) => {
        if (item?.dr_name && item?.dr_image && item?.dr_speciality) {
          valid = true
          return item
        } else if (!item?.dr_name && !item?.dr_image && !item?.dr_speciality) {
          valid = true
          return undefined
        } else {
          valid = false

          if (!item?.dr_name) {
            message = `Please enter  doctor name for ${doctorArray[index].name}`
          } else if (!item?.dr_image) {
            message = `Please enter doctor image for ${doctorArray[index].name}`
          } else if (!item?.dr_speciality) {
            message = `Please doctor speciality for ${doctorArray[index].name}`
          }

          return undefined
        }
      })
    } else {
      finalPayload = []
      valid = false
      message = 'Please add at least one doctor with all details'
    }
    console.log('first', finalPayload, base64Array)
    if (finalPayload && finalPayload.length > 0 && valid) {
      const reqPayload = finalPayload?.filter((item) => item !== undefined)
      dispatch(createDoctors(requestGenerator(reqPayload))).then((e) => {
        if (e.type === `${CREATE_DOCTOR_TYPE}/fulfilled`) {
          reset()
          dispatch(getDoctors(requestGenerator({})))
        }
      })
    } else {
      dispatch(
        setMessage({
          message,
          type: failure,
        })
      )
    }
  }

  const handleChange = (e: any) => {
    const value = e.target.value
    if (value.length === 1 && value === ' ') {
      e.target.value = ''
    } else if (
      value.length > 1 &&
      value[0] === ' ' &&
      value[value.length - 1] === ' '
    ) {
      e.target.value = value.trim()
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.proactDoctorContaier}>
        <h1 className={styles.proactDoctorTitle}>PROACT Doctor</h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.proactDoctorForm}
        >
          {doctorArray.map((doctorData) => {
            let fillName: any = formData[`${doctorData.id}`]
              ? formData[`${doctorData.id}`][`${DOCTOR_IMAGE}`]
              : {}
            return (
              <div key={doctorData.id} className={styles.formContainer}>
                <div className={styles.doctorName}>{doctorData.name}</div>
                <div className={styles.formFieldContaner}>
                  <div className={styles.inputFieldContainer}>
                    <label
                      htmlFor={`${doctorData.id}.${DOCTOR_NAME}`}
                      className={styles.formLabel}
                    >
                      Doctor Name
                    </label>
                    <input
                      type="text"
                      placeholder="Doctor name"
                      className={styles.formInput}
                      {...register(`${doctorData.id}.${DOCTOR_NAME}`)}
                      onChange={(e) => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formFieldContaner}>
                  <div className={styles.inputFieldContainer}>
                    <label
                      htmlFor={`${doctorData.id}.${DOCTOR_SPECIALITY}`}
                      className={styles.formLabel}
                    >
                      Specialty
                    </label>
                    <input
                      type="text"
                      placeholder="Doctor specialty"
                      className={styles.formInput}
                      {...register(`${doctorData.id}.${DOCTOR_SPECIALITY}`)}
                      onChange={(e) => {
                        handleChange(e)
                      }}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className={styles.formFieldContaner}>
                  <div className={styles.inputFieldContainer}>
                    <label
                      htmlFor={`${doctorData.id}.${DOCTOR_IMAGE}`}
                      className={styles.formLabel}
                    >
                      Doctor Image
                    </label>
                    <AttachFiles
                      register={register}
                      fileKey={`${doctorData.id}.${DOCTOR_IMAGE}`}
                      id={`file-${doctorData.id}`}
                      fileList={fillName}
                    />
                  </div>
                </div>
              </div>
            )
          })}
          <div className={styles.formButtonContainer}>
            <Button title="Submit" type="submit" />
            <Button
              title="Reset"
              type="button"
              customClass={styles.resetBtn}
              handleClick={() => {
                dispatch(clearState())
              }}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default ProactDoctor
