import { FC } from "react";
import { colors } from "../../../../constants/color";
import Divider from "../../divider/Divider";
import { CloseIcon } from "../../svg-components";
import styles from "./deletePopup.module.scss";
import { useAppDispatch } from "../../../../hooks";
import Button from "../../button/Button";
import { useLocation } from "react-router-dom";
import {
  deleteMedicalCenterById,
  getAllMedicalCenter,
} from "../../../../redux/features/medical-center/medicalCenterAsynActions";
import { requestGenerator } from "../../../../utils/payloadGenerator";
import {
  DELETE_MEDICAL_CENTER_BY_ID,
  DELETE_PACKAGE_BY_ID,
} from "../../../../constants/asyncActionsType";
import {
  deletePackageById,
  getAllPackage,
} from "../../../../redux/features/package/packageAsyncActions";
interface IDeletePopup {
  popData?: any;
}
const DeletePopup: FC<IDeletePopup> = ({ popData }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const handleYes = () => {
    let data = {
      search: "",
      pkg: "",
    };
    let deletePayload = {
      id: popData?._id,
    };
    dispatch(deletePackageById(requestGenerator(deletePayload))).then((e) => {
      if (e.type === `${DELETE_PACKAGE_BY_ID}/fulfilled`) {
        dispatch(getAllPackage(requestGenerator(data))); //getAllPackages
      }
    });
    // dispatch(getAllPackage(requestGenerator(data)));
    // if (location?.pathname === "/medicalcenter") {
    //   dispatch(deleteMedicalCenterById(requestGenerator(deletePayload))).then(
    //     (e) => {
    //       if (e.type === `${DELETE_MEDICAL_CENTER_BY_ID}/fulfilled`) {
    //         dispatch(getAllMedicalCenter(requestGenerator(data))); //getAllMedicalCenterApi
    //       }
    //     }
    //   );
    // } else if (location?.pathname === "/package") {
    //   dispatch(deletePackageById(requestGenerator(deletePayload))).then((e) => {
    //     if (e.type === `${DELETE_PACKAGE_BY_ID}/fulfilled`) {
    //       dispatch(getAllPackage(requestGenerator(data))); //getAllPackages
    //     }
    //   });
    // }
  };
  return (
    <>
      <div className={styles.deletePopupContainer}>
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
        />
        <div className={styles.deleteContainer}>
          <p className={styles.title}>Are you sure?</p>
          <Divider customClass={styles.dividerStyle} />

          <div className={styles.confirmationContainer}>
            <p className={styles.confirmationText}>
              Are you sure you want to delete the {popData?.mc_name} ?
            </p>
            <div className={styles.buttonContainer}>
              <Button
                title="Yes"
                customClass={styles.yesButtonStyle}
                handleClick={handleYes}
              />
              <Button
                title="No"
                customClass={styles.noButtonStyle}
                handleClick={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeletePopup;
