import { FC } from 'react'
import styles from '../attachfiles.module.scss'
import { UseFormRegister } from 'react-hook-form'
import { Attachments } from '../../svg-components'
import { colors } from '../../../../constants/color'
import { isDataUri } from '../../../../utils/utils'

interface IAttachFiles {
  fileKey: any
  error?: string
  register: UseFormRegister<any>
  validation?: any
  id: string
  fileList: any
  dURI?: any
}

const AttachFiles: FC<IAttachFiles> = ({
  fileKey,
  error,
  register,
  validation,
  id,
  fileList,
}) => {
  const handleFileInputOpen = (element: string) => {
    const fileInput = document.getElementById(element)
    fileInput?.click()
  }

  return (
    <div className={styles.attachmentsContainer}>
      <div className={styles.attachmentsIconListContainer}>
        <span onClick={() => handleFileInputOpen(id)}>
          <input
            type="file"
            id={id}
            accept="image/jpeg, image/png"
            style={{ display: 'none' }}
            {...register(fileKey, validation ? validation : {})}
          />
          <Attachments
            fillColor={colors.white1}
            fillColor1={colors.green1}
            customClass={styles.attchmentsIcon}
          />
        </span>
        <div className={styles.attachedFileListsContainer}>
          <div className={styles.attachedFile}>
            <span className={styles.attachedFileName}>
              {/* {Object.values(fileList).length > 0 && fileList[0].name} */}

              {isDataUri(fileList) ? (
                <img src={fileList} alt="" className={styles.attachedImg} />
              ) : (
                fileList[0] && (
                  <img
                    src={URL.createObjectURL(fileList[0])}
                    alt="img"
                    className={styles.attachedImg}
                  />
                )
              )}
            </span>
          </div>
        </div>
      </div>
      <p className="dashboardFormError">{error}</p>
    </div>
  )
}

export default AttachFiles
