import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { success } from '../../../constants/data'

interface ToastState {
  message: string
  type: typeof success
}

const initialState: ToastState = {
  message: '',
  type: success,
}

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<ToastState>) => {
      state.message = action.payload.message
      state.type = action.payload.type
    },
    clearMessage: (state) => {
      state.message = ''
      state.type = success
    },
  },
})

export const { setMessage, clearMessage } = toastSlice.actions

export default toastSlice.reducer
