// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.proactNews_mainContainer__MFTp7 {
  width: 98%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  margin: 0px 20px 20px 0px;
}
.proactNews_mainContainer__MFTp7 .proactNews_title__LF4lv {
  color: var(--black1);
  font-size: var(--font-18);
  font-weight: var(--font-bold);
  padding: 20px;
}
.proactNews_mainContainer__MFTp7 .proactNews_cardComponent__NW1FD {
  width: 97%;
  height: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.proactNews_mainContainer__MFTp7 .proactNews_buttonConatiner__Mgus7 {
  height: 100px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.proactNews_mainContainer__MFTp7 .proactNews_buttonConatiner__Mgus7 .proactNews_resetButtonStyle__gcb35 {
  background: none;
  border: 1px solid var(--blue1);
  border-radius: 6px;
  color: var(--blue1);
}`, "",{"version":3,"sources":["webpack://./src/pages/mobileapp-configuraion/proact-news/proactNews.module.scss","webpack://./src/styles/mixin.scss"],"names":[],"mappings":"AACA;EACE,UAAA;EACA,mBAAA;EACA,oCAAA;EACA,mDAAA;EACA,yBAAA;AAAF;AACE;ECGA,oBDFgB;ECGhB,yBDH+B;ECI/B,6BDJ+C;EAC7C,aAAA;AAGJ;AADE;EACE,UAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AAGJ;AAAE;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;AAEJ;AADI;EACE,gBAAA;EACA,8BAAA;EACA,kBAAA;EACA,mBAAA;AAGN","sourcesContent":["@import \"../../../styles/mixin.scss\";\n.mainContainer {\n  width: 98%;\n  border-radius: 10px;\n  background: rgba(255, 255, 255, 0.7);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  margin: 0px 20px 20px 0px;\n  .title {\n    @include font(var(--black1), var(--font-18), var(--font-bold));\n    padding: 20px;\n  }\n  .cardComponent {\n    width: 97%;\n    height: 100%;\n    margin: 0px auto;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    // justify-content: space-between;\n  }\n  .buttonConatiner {\n    height: 100px;\n    display: flex;\n    justify-content: center;\n    margin-top: 30px;\n    .resetButtonStyle {\n      background: none;\n      border: 1px solid var(--blue1);\n      border-radius: 6px;\n      color: var(--blue1);\n    }\n  }\n}\n","@mixin pageWrapper($padding) {\n  background: var(--white2);\n  backdrop-filter: blur(6px);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: $padding;\n  margin: 20px 20px 30px 0px;\n}\n\n@mixin font($color, $size, $weight) {\n  color: $color;\n  font-size: $size;\n  font-weight: $weight;\n}\n\n@mixin flexCenter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@mixin flexVertical {\n  display: flex;\n  align-items: center;\n}\n\n@mixin responsive($width) {\n  @media (max-width: $width) {\n    @content;\n  }\n}\n\n@mixin animation($name) {\n  @keyframes #{$name} {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `proactNews_mainContainer__MFTp7`,
	"title": `proactNews_title__LF4lv`,
	"cardComponent": `proactNews_cardComponent__NW1FD`,
	"buttonConatiner": `proactNews_buttonConatiner__Mgus7`,
	"resetButtonStyle": `proactNews_resetButtonStyle__gcb35`
};
export default ___CSS_LOADER_EXPORT___;
