import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { colors } from "../../../../constants/color";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getPackageById } from "../../../../redux/features/package/packageAsyncActions";
import { requestGenerator } from "../../../../utils/payloadGenerator";
import Divider from "../../divider/Divider";
import Loader from "../../spinner/Loader";
import { CloseIcon } from "../../svg-components";
import styles from "./detailsPopup.module.scss";

interface IPropsData {
  popData?: any;
  handleClose?: any;
}

const DetailsPopup: FC<IPropsData> = ({ popData, handleClose }) => {
  // const location = useLocation();
  // const { loading, packageInfo } = useAppSelector((state) => state.package);
  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(getPackageById(requestGenerator(location.state)));
  // }, []);
  // console.log("poppp>>>>", popData);

  return (
    <>
      {/* {loading && <Loader />} */}
      <div
        className={styles.notesPopupContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={handleClose}
        />
        <div className={styles.notesContainer}>
          <p className={styles.title}>Details</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.dateInfo}>
            <p className={styles.dateText}>
              Package Name :{" "}
              <span className={styles.spanText}>{popData?.name}</span>
            </p>
            {popData
              ? popData?.module_ids?.length > 0 &&
                popData?.module_ids.map((item: any, index: any) => {
                  return (
                    <li key={index} className={styles.descriptionText}>
                      {item.name}
                    </li>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsPopup;
