import { FC } from "react";
import { Outlet } from "react-router-dom";

interface IAppProps {}

const PackageLayout: FC<IAppProps> = () => {
  return <Outlet />;
};

export default PackageLayout;
