import { ReactElement } from 'react'
import ManageMedicalCenter from '../../../pages/medical-center/manage-medical-center/ManageMedicalCenter'
import MainLayout from '../../../pages/main-layout/MainLayout'
import NotFound from '../../common/not-found/NotFound'
import Login from '../../../pages/login/Login'
import AuthWrapper from './AuthWrapper'
import MedicalCenterLayout from '../../../pages/medical-center/MedicalCenterLayout'
import PackageLayout from '../../../pages/packages/PackageLayout'
import PacakgeList from '../../../pages/packages/package-list/PackageList'
import ManagePackages from '../../../pages/packages/manage-packages/ManagePackages'
import LandingPage from '../../common/landing-page/LandingPage'
import ForgotPassword from '../../../pages/login/forgot-password/ForgotPassword'
import OtpVerfication from '../../../pages/login/otp-verfication/OtpVerfication'
import RecoveryPassword from '../../../pages/login/recovery-password/RecoveryPassword'
import MobileAppConfigLayout from '../../../pages/mobileapp-configuraion/MobileAppConfigLayout'
import ProactMedicalCenter from '../../../pages/mobileapp-configuraion/proact-medical-center/ProactMedicalCenter'
import ProactNews from '../../../pages/mobileapp-configuraion/proact-news/ProactNews'
import ProactDoctor from '../../../pages/mobileapp-configuraion/proact-doctor/ProactDoctor'
import MedicalCenter from '../../../pages/medical-center/medical-center-grid/MedicalCenter'
import MedicalCenterUtility from '../../../pages/medical-center/medical-center-utility/MedicalCenterUtility'

export interface MyRoutes {
  path: string
  element: ReactElement<any, any>
  children: MyRoutes[]
  header: string
  location?: string
  roles?: string[]
}

export const routes: MyRoutes[] = [
  {
    path: '/',
    element: <LandingPage />,
    children: [
      {
        path: '',
        element: <Login />,
        children: [],
        header: '',
        location: '/',
      },
      {
        path: 'forgotpassword',
        element: <ForgotPassword />,
        children: [],
        header: '',
        location: '/forgotpassword',
      },
      {
        path: 'otpverfication',
        element: <OtpVerfication />,
        children: [],
        header: '',
        location: '/otpverfication',
      },
      {
        path: 'resetpassword',
        element: <RecoveryPassword />,
        children: [],
        header: '',
        location: '/resetpassword',
      },
    ],
    header: '',
    location: '/',
  },
  {
    path: '/*',
    element: (
      <AuthWrapper>
        <MainLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: 'medicalcenter/*',
        element: <MedicalCenterLayout />,
        children: [
          {
            path: '',
            element: <MedicalCenter />,
            children: [],
            header: ' Manage Medical Centers',
            location: '/medicalcenter',
            roles: ['Super Admin'],
          },
          {
            path: 'managemedicalcenter',
            element: <ManageMedicalCenter />,
            children: [],
            header: ' Manage Medical Centers',
            location: '/medicalcenter/managemedicalcenter',
            roles: ['Super Admin'],
          },
          {
            path: 'medicalcenter-utility',
            element: <MedicalCenterUtility />,
            children: [],
            header: ' Setup Medical Centers Utility',
            location: '/medicalcenter/medicalcenter-utility',
            roles: ['Super Admin'],
          },
          {
            path: '*',
            element: <NotFound />,
            children: [],
            header: 'Page Not Found',
            roles: ['Super Admin'],
          },
        ],
        header: ' Manage Medical Centers',
        location: '/medicalcenter',
        roles: ['Super Admin'],
      },
      {
        path: 'package/*',
        element: <PackageLayout />,
        children: [
          {
            path: '',
            element: <PacakgeList />,
            children: [],
            header: 'Manage Packages',
            location: '/package',
            roles: ['Super Admin'],
          },
          {
            path: 'managepackage',
            element: <ManagePackages />,
            children: [],
            header: 'Manage Packages',
            location: '/package/managepackage',
            roles: ['Super Admin'],
          },
          {
            path: '*',
            element: <NotFound />,
            children: [],
            header: 'Page Not Found',
            roles: ['Super Admin'],
          },
        ],
        header: 'Manage Packages',
        location: '/package',
        roles: ['Super Admin'],
      },
      {
        path: 'mobileappconfiguration/*',
        element: <MobileAppConfigLayout />,
        children: [
          {
            path: '',
            element: <MobileAppConfigLayout />,
            children: [],
            header: 'Mobile App Configurations',
            location: '/mobileappconfiguration',
            roles: ['Super Admin'],
          },
          {
            path: 'medicalcenter',
            element: <ProactMedicalCenter />,
            children: [],
            header: 'Mobile App Configurations',
            location: '/mobileappconfiguration/medicacenter',
            roles: ['Super Admin'],
          },
          {
            path: 'news',
            element: <ProactNews />,
            children: [],
            header: 'Mobile App Configurations',
            location: '/mobileappconfiguration/news',
            roles: ['Super Admin'],
          },
          {
            path: 'doctor',
            element: <ProactDoctor />,
            children: [],
            header: 'Mobile App Configurations',
            location: '/mobileappconfiguration/doctor',
            roles: ['Super Admin'],
          },
        ],
        header: 'Mobile App Configurations',
        location: '/mobileappconfiguration',
        roles: ['Super Admin'],
      },
      {
        path: '*',
        element: <NotFound />,
        children: [],
        header: 'Page Not Found',
        roles: ['Super Admin'],
      },
      {
        path: 'notfound',
        element: <NotFound />,
        children: [],
        header: 'Page Not Found',
        roles: ['Super Admin'],
      },
      {
        path: 'notpermitted',
        element: <NotFound />,
        children: [],
        header: 'Page Not Found',
        roles: ['Super Admin'],
      },
    ],
    header: 'Home',
    roles: ['Super Admin'],
  },
]
