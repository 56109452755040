import { FC, useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import Button from '../../../components/common/button/Button'
import ProactNewsCard from '../../../components/common/proact-news-card/ProactNewsCard'
import { failure, proactNewsDataArray } from '../../../constants/data'
import { IProactNewsCardForm } from '../../../interfaces/interfaces'
import styles from './proactNews.module.scss'
import { useAppSelector, useAppDispatch } from '../../../hooks/index'
import {
  createProactNews,
  getAllProactNews,
} from '../../../redux/features/mobile-app-config/proactMobileAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import Loader from '../../../components/common/spinner/Loader'
import { clearState } from '../../../redux/features/mobile-app-config/proactMobileAppSlice'
import { setMessage } from '../../../redux/features/toast/toastSlice'

interface IProactNews {}
const ProactNews: FC<IProactNews> = () => {
  const { isLoading, proactNewsData } = useAppSelector(
    (state) => state.proactMobileConfig
  )
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IProactNewsCardForm>()

  useEffect(() => {
    dispatch(getAllProactNews(requestGenerator({})))
  }, [dispatch])
  const onSubmit: SubmitHandler<IProactNewsCardForm> = (data) => {
    const payload = Object.values(data)
    let valid = false
    let message = 'Please add at least one news with all details'
    let finalPayload: any[] = []
    if (payload?.some((item) => item?.title && item?.description)) {
      finalPayload = payload?.map((item, index) => {
        if (item?.title && item?.description) {
          valid = true
          return item
        } else if (!item?.title && item?.description) {
          valid = false
          message = `Please provide a title for news ${index + 1}`
          return undefined
        } else if (item?.title && !item?.description) {
          valid = false
          message = `Please provide a description for news ${index + 1}`
          return undefined
        }
      })
    } else {
      finalPayload = []
      valid = false
      message = 'Please add at least one news with all details'
    }
    if (finalPayload && finalPayload.length > 0 && valid) {
      const reqPayload = finalPayload?.filter((item) => item !== undefined)
      console.log('reqPayload', reqPayload)
      dispatch(createProactNews(requestGenerator(reqPayload)))
    } else {
      dispatch(
        setMessage({
          message,
          type: failure,
        })
      )
    }
  }

  useEffect(() => {
    if (proactNewsData.length > 0) {
      const arr = proactNewsData?.map((item: any, number: number) => {
        return {
          title: item?.title,
          description: item?.description,
        }
      })
      let formdata = { ...arr }
      reset(formdata)
    } else {
      const obj = { title: '', description: '' }
      const formData = proactNewsDataArray?.map((item: any) => {
        return obj
      })
      let formDataMap = { ...formData }
      reset(formDataMap)
    }
  }, [reset, proactNewsData])

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.mainContainer}>
        <p className={styles.title}>PROACT News</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.cardComponent}>
            {proactNewsDataArray?.map((item: any, i: number) => {
              return (
                <ProactNewsCard
                  item={item}
                  index={i}
                  key={i}
                  register={register}
                  errors={errors}
                />
              )
            })}
          </div>
          <div className={styles.buttonConatiner}>
            <Button title="Submit" type="submit" />
            <Button
              title="Reset"
              type="button"
              customClass={styles.resetButtonStyle}
              handleClick={() => {
                dispatch(clearState())
              }}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default ProactNews
