// login
export const USER_LOGIN_TYPE = "login/userLogin";
export const USER_FORGOT_PASSWORD_TYPE = "login/userForgotpassword";
export const VERIFY_OTP_TYPE = "login/userVerifyOtp";
export const RESET_PASSWORD_TYPE = "login/userResetPassword";
export const RESEND_OTP_TYPE = "login/userResendOtp";
export const USER_LOGOUT_TYPE = "login/userLogout";
//Medical Center
export const GET_ALL_MEDICAL_CENTER = "medicalCenter/getAllMedicalCenter";
export const ADD_MEDICAL_CENTER_TYPE = "medicalCenter/createMedicalCenter";
export const GET_ALL_MEDICAL_CENTER_BY_ID =
  "medicalCenter/getAllMedicalCenterById";
export const UPDATE_MEDICAL_CENTER_BY_ID =
  "medicalCenter/updateMedicalCenterById";
export const DELETE_MEDICAL_CENTER_BY_ID =
  "medicalCenter/deleteMedicalCenterById";
export const ACTIVE_MEDICAL_CENTER_BY_ID =
  "medicalCenter/activeMedicalCenterById";
export const ALL_MEDICAL_CENTER_USER = "medicalCenter/allMedicalCenterUser";
//Package
export const GET_ALL_PACKAGE = "package/getAllPackage";
export const ADD_PACKAGE = "package/addPackage";
export const EDIT_PACKAGE = "package/editPackage";
export const GET_ALL_PACKAGE_MODULES = "package/getAllPackageModules";
export const GET_PACKAGE_BY_ID = "package/getPackageById";
export const DELETE_PACKAGE_BY_ID = "package/deletePackageById";
export const UPDATE_STATUS_BY_ID = "package/updateStatusPackageById";

// mobile-config proact medical center
export const CREATE_MEDICAL_CENTER = "proactMedicalCenter/createMedicalCenter";
export const GET_MEDICAL_CENTER = "proactMedicalCenter/getMedicalCenter";
export const CREATE_PROACT_NEWS = "proactMobileConfigSlice/createProactNews";
export const GET_ALL_PROACT_NEWS = "proactMobileConfigSlice/getAllProactNews";

// proact doctor configuration

export const CREATE_DOCTOR_TYPE = "proactMobileConfigSlice/createDoctors";
export const GET_DOCTOR_TYPE = "proactMobileConfigSlice/getDoctors";

// medical center utlity
export const GET_ALL_MEDICAL_CENTER_TYPE =
  "getAllMedicalUtylity/getAllMcUtility";
export const ADD_DATABASE_URI_TYPE = "getAllMedicalUtylity/addDataBaseUriData";
export const AWS_CONNECTION_TYPE = "getAllMedicalUtylity/awsConnectionData";
export const UPDATE_CONFIG_DATA_TYPE = "getAllMedicalUtylity/updateConfig";
