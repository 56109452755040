import { createSlice } from "@reduxjs/toolkit";
import {
  createDoctors,
  createMedicalCenters,
  createProactNews,
  getAllProactMedicalCenter,
  getAllProactNews,
  getDoctors,
} from "./proactMobileAsyncActions";
import { IProactMobileConfigSlice } from "../../../interfaces/apiInterface";

const initialState: IProactMobileConfigSlice = {
  isLoading: false,
  proactNewsData: [],
  proactMedicalCenterData: [],
  proactDoctorsData: [],
  error: null,
};
export const proactMobileConfigSlice = createSlice({
  name: "proactMobileConfigSlice",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.error = "";
      state.proactNewsData = [];
      state.proactDoctorsData = [];
      state.proactMedicalCenterData = [];
    },
  },
  extraReducers: (builder) => {
    // get all proact news
    builder
      .addCase(getAllProactNews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProactNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.proactNewsData = action.payload;
      })
      .addCase(getAllProactNews.rejected, (state, error) => {
        state.isLoading = false;
        state.error = error;
      });

    // create proact news
    builder
      .addCase(createProactNews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProactNews.fulfilled, (state) => {
        state.isLoading = false;
        // state.proactNewsData = action.payload?.data;
      })
      .addCase(createProactNews.rejected, (state, error) => {
        state.isLoading = false;
        state.error = error;
      });

    // create proact medical center
    builder.addCase(createMedicalCenters.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createMedicalCenters.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.
    });
    builder.addCase(createMedicalCenters.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    // get all proact medical center
    builder.addCase(getAllProactMedicalCenter.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllProactMedicalCenter.fulfilled, (state, action) => {
      state.isLoading = false;
      state.proactMedicalCenterData = action.payload;
    });
    builder.addCase(getAllProactMedicalCenter.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });
    // get all proact doctors for mobile app
    builder.addCase(getDoctors.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDoctors.fulfilled, (state, action) => {
      state.isLoading = false;
      state.proactDoctorsData = action.payload;
    });
    builder.addCase(getDoctors.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    // create proact doctor for mobile app
    builder.addCase(createDoctors.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createDoctors.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createDoctors.rejected, (state, error) => {
      state.isLoading = false;
    });
  },
});
export const { clearState } = proactMobileConfigSlice.actions;
export default proactMobileConfigSlice.reducer;
