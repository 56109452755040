import { FC } from 'react'
import { useRoutes } from 'react-router-dom'
import { routes } from './routeData'
interface RoutesProps {}

const RootRoutes: FC<RoutesProps> = (props) => {
  const routing = useRoutes(routes)
  return routing
}
export default RootRoutes
