import { useState, FC } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import styles from './login.module.scss'
import { loginValidators } from '../../form-validators/loginValidators'
import { EmailIcon, PasswordIcon } from '../../components/common/svg-components'
import Button from '../../components/common/button/Button'
import { ILoginFormInputs, passwordType } from '../../interfaces/interfaces'
import EyeIcons from '../../components/common/eye-icon/EyeIcon'
import { EMAIL, PASSWORD } from '../../constants/constant'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import { trimValue, uuid } from '../../utils/utils'
import { requestGenerator } from '../../utils/payloadGenerator'
import { setEncryptionKey } from '../../redux/features/login/loginSlice'
import Loader from '../../components/common/spinner/Loader'
import { userLogin } from '../../redux/features/login/loginAsynActions'
import { USER_LOGIN_TYPE } from '../../constants/asyncActionsType'

const Login: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [passwordType, setPasswordType] = useState<passwordType>('password')
  const { loading } = useAppSelector((state) => state.login)

  const {
    register,
    handleSubmit,
    // setError,
    formState: { errors },
  } = useForm<ILoginFormInputs>()

  const onSubmit: SubmitHandler<ILoginFormInputs> = (data) => {
    const staticKey: string = uuid()
    dispatch(setEncryptionKey(staticKey))
    dispatch(userLogin(requestGenerator(data))).then((e) => {
      if (e.type === `${USER_LOGIN_TYPE}/fulfilled`) {
        navigate('/medicalcenter')
      }
    })
  }
  return (
    <>
      {loading && <Loader />}
      <p className={styles.formTitle}>Sign In</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.loginForm}>
          <span className={styles.iconLabelStyle}>
            <EmailIcon customClass={styles.iconCustomClass} />
            <label htmlFor={EMAIL} className={styles.labelStyle}>
              Email
            </label>
          </span>
          <div className={styles.inputFieldContainer}>
            <input
              type="text"
              placeholder="Enter email"
              className={styles.inputFieldStyle}
              {...register(EMAIL, loginValidators[EMAIL])}
            />
            <p className={styles.errorText}>
              {errors.email && (
                <span className="error">{errors.email.message}</span>
              )}
            </p>
          </div>
        </div>
        <div className={styles.loginForm}>
          <span className={styles.iconLabelStyle}>
            <PasswordIcon customClass={styles.iconCustomClass} />
            <label htmlFor={PASSWORD} className={styles.labelStyle}>
              Password
            </label>
          </span>
          <div>
            <span style={{ position: 'relative' }}>
              <input
                type={passwordType}
                placeholder="Enter password"
                className={styles.inputFieldStyle}
                {...register(PASSWORD, loginValidators[PASSWORD])}
              />
              <EyeIcons
                passwordType={passwordType}
                setPasswordType={setPasswordType}
                customClass={styles.eyeIcon}
              />
            </span>
            <p className={styles.errorText}>
              {errors.password && (
                <span className="error">{errors.password.message}</span>
              )}
            </p>
          </div>
        </div>
        <Button
          title="Sign In"
          type="submit"
          customClass={styles.loginButtonStyle}
          disable={loading}
        />
      </form>
    </>
  )
}

export default Login
