import { FC, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Tabs from '../../components/common/tabs/Tabs'
import { mobileAppConfigTabData } from '../../constants/data'

interface IMobileAppConfig {}

const MobileAppConfigLayout: FC<IMobileAppConfig> = (pop) => {
  const navigate = useNavigate()
  const location = useLocation().pathname
  useEffect(() => {
    if (location === '/mobileappconfiguration') {
      navigate('/mobileappconfiguration/medicalcenter')
    }
  }, [location])
  return (
    <>
      <Tabs tabData={mobileAppConfigTabData} />
      <Outlet />
    </>
  )
}

export default MobileAppConfigLayout
