import { FC, useEffect } from 'react';
import styles from './createdUsersPopup.module.scss';
import { CloseIcon } from '../svg-components/index';
import { colors } from '../../../constants/color';
import Divider from '../divider/Divider';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  getAllMedicalCenterById,
  getAllMedicalCenterUser,
} from '../../../redux/features/medical-center/medicalCenterAsynActions';
import { requestGenerator } from '../../../utils/payloadGenerator';
import Loader from '../spinner/Loader';

interface IPropsData {
  popData?: any;
  handleClose?: any;
}
const CreatedUsersPopup: FC<IPropsData> = ({ popData, handleClose }) => {
  const dispatch = useAppDispatch();
  const { isLoading, createUserData } = useAppSelector(
    (state) => state.medicalCenter
  );

  useEffect(() => {
    let payload = {
      id: popData?._id,
    };
    let requestPayload = {
      search: '',
      pkg: '',
      page: 0,
      pageSize: 10000,
      mc_id: popData?._id,
    };
    dispatch(getAllMedicalCenterUser(requestGenerator(requestPayload)));
  }, [dispatch, popData?._id]);
  return (
    <>
      {isLoading && <Loader />}
      <div
        className={styles.popupContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={handleClose}
        />
        <div className={styles.usersContainer}>
          <p className={styles.title}>Created Users</p>
          <Divider customClass={styles.dividerStyle} />
          <p className={styles.subTitle}>USERS NAME</p>
          <div className={styles.usersList}>
            {createUserData?.map((item: any, index: number) => {
              return (
                item.name && (
                  <p className={styles.userName} key={index}>
                    {item.name}
                  </p>
                )
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatedUsersPopup;
