import { EMAIL, PASSWORD } from '../constants/constant'

export interface ILoginValidators {
  [EMAIL]: {
    required: string
    pattern: {
      value: RegExp
      message: string
    }
  }
  [PASSWORD]: {
    required: string
    // pattern: {
    //   value: RegExp;
    //   message: string;
    // };
  }
}

export const loginValidators: ILoginValidators = {
  [EMAIL]: {
    required: 'Please enter email',
    pattern: {
      value: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/,
      // value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      message: 'Please enter valid email',
    },
  },
  [PASSWORD]: {
    required: 'Please enter password',
    // pattern: {
    //   value: /^(?=.*[a-zA-Z])(?=.*[0-9])(?!.*\s).{8,}$/,
    //   message: "Space is not allowed",
    // },
    // pattern: {
    //   value:
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{6,16}$/,
    //   message:
    //     "password must be 8-16 characters long, 1 uppercase ,1 lowercase character, 1 number & 1 special character",
    // },
  },
}
