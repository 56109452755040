import {
  MODULES,
  PACKAGE_NAME,
  USER_LIMIT,
  VALIDITY_DAYS,
} from "../constants/constant";

export interface IAddPackageValidators {
  [PACKAGE_NAME]: {
    required: string;
    pattern: object;
  };
  [USER_LIMIT]: {
    required: string;
  };
  [VALIDITY_DAYS]: {
    required: string;
  };
  [MODULES]: {
    required: string;
  };
}

export const addPackageValidators = {
  [PACKAGE_NAME]: {
    required: "Please enter package name",
    // pattern: {
    //   value: /^\S+$/,
    //   message: "Package name cannot contain only whitespace",
    // },
  },
  [USER_LIMIT]: {
    required: "Please enter user limit",
  },
  [VALIDITY_DAYS]: {
    required: "Please enter validity days",
  },
  [MODULES]: {
    required: "Please select one modules",
  },
};
