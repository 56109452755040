import { createSlice } from '@reduxjs/toolkit'
import { IPhoneNO } from '../../../interfaces/interfaces'

const initialState: IPhoneNO = {
  userPhoneNo: '',
}

export const commonSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setPhoneNo: (state, action) => {
      state.userPhoneNo = action.payload
    },
  },
})

export const { setPhoneNo } = commonSlice.actions

export default commonSlice.reducer
