import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DropDownArrowIcon,
  DropDownIcon,
  DropDownOpenIcon,
} from "../svg-components";
import styles from "./dropDown.module.scss";
import {
  getAllMedicalCenter,
  getAllMedicalCenterById,
} from "../../../redux/features/medical-center/medicalCenterAsynActions";
import { requestGenerator } from "../../../utils/payloadGenerator";
import { useAppDispatch } from "../../../hooks";

interface IDropDown {
  dropDownData: Array<any>;
  dropdownInitialState: string;
  customClassContainer?: string;
  customClassContent?: string;
  handleChangeDropdown?: (e: React.SyntheticEvent<EventTarget>) => void;
  isAllData?: boolean;
}

const DropDown: FC<IDropDown> = ({
  dropDownData,
  dropdownInitialState,
  customClassContainer,
  customClassContent,
  handleChangeDropdown,
  isAllData,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [state, setState] = useState<boolean>(false);
  const [value, setValue] = useState(dropdownInitialState);
  const [dataPerPage, setDataPerPage] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(1);

  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = [];
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i);
    }
    return pageIndexOptions;
  };
  const pageIndexOptions = pageIndexArray();

  const handlevalue = (item: any) => {
    setValue(item.name);
    setState(false);
    navigate("", {
      state: {
        pkg: item._id,
      },
    });
  };

  const handleAllPackage = () => {
    setState(!state);
    setValue("Select package");
    // Api call for Get All Medical Center
    const requestData = {
      search: "",
      pkg: "",
      page: 0,
      pageSize: 0,
    };
    dispatch(getAllMedicalCenter(requestGenerator(requestData)));
  };
  return (
    <>
      <div
        className={styles.mainContainer}
        onChange={(e) => handleChangeDropdown && handleChangeDropdown(e)}
      >
        <div
          onClick={() => setState(!state)}
          className={[styles.dropDownContainer, customClassContainer].join(" ")}
        >
          <span
            className={
              value !== dropdownInitialState
                ? styles.dropDownItem
                : styles.placeholder
            }
          >
            {value}
          </span>

          <span className={styles.dropDownIcon}>
            {!state ? (
              <DropDownIcon fillColor="#797979" />
            ) : (
              <DropDownArrowIcon fillColor="#797979" />
            )}
            {/* <DropDownIcon fillColor="#474973" /> */}
          </span>
        </div>
        {state && (
          <div
            className={[styles.dropDownContent, customClassContent].join(" ")}
          >
            {isAllData && (
              <p
                className={styles.dropDownValue}
                onClick={() => handleAllPackage()}
              >
                Select package
              </p>
            )}
            {dropDownData?.map((item, index) => {
              return (
                <>
                  <p
                    className={styles.dropDownValue}
                    key={index}
                    onClick={() => handlevalue(item)}
                  >
                    {item.name}
                  </p>
                </>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default DropDown;
