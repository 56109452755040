import { FC } from "react";
import styles from "./notFound.module.scss";
import notFound404 from "../../../assets/images/notFound404.png";
import notFoundVector from "../../../assets/images/notFoundVector.png";
import Button from "../button/Button";
import { useNavigate } from "react-router-dom";

const NotFound: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.notFoundCointainer}>
        <span className={styles.notFoundText404}> 404 </span>
        <p className={styles.notFoundText}>Oops! Sorry page not found</p>
        <img
          src={notFoundVector}
          alt="notFound-Vector"
          className={styles.notFoundVectorStyle}
        />
        <div className={styles.notFoundButtonContainer}>
          <Button
            title="Back to Home"
            type="button"
            handleClick={() => navigate("/")}
          />
        </div>
      </div>
    </>
  );
};

export default NotFound;
