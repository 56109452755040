import {
  ADD_DATABASE_URI,
  AWS_CONNECTION,
  GET_ALL_MEDICAL_CENTER_Utility,
  UPDATE_CONFIG_DATA,
} from "./../../../config/config";
import axios from "axios";
import { IAPIPayload } from "../../../interfaces/apiInterface";

// get dropdown value
export const getAllMcUtility = (data: IAPIPayload) => {
  return axios.post(GET_ALL_MEDICAL_CENTER_Utility, data);
};

// add databse uri
export const addDataBaseUriData = (data: IAPIPayload) => {
  return axios.post(ADD_DATABASE_URI, data);
};

// awsConnection
export const awsConnectionData = (data: IAPIPayload) => {
  return axios.post(AWS_CONNECTION, data);
};

// update Config
export const updateConfig = (data: IAPIPayload) => {
  return axios.post(UPDATE_CONFIG_DATA, data);
};
