import { FC } from 'react'
import DropDown from '../dropdown/DropDown'
import styles from './searchFilter.module.scss'
import Button from '../button/Button'
import { SearchButton } from '../svg-components'

interface ISearchFilter {
  title: string
  dropDownData: Array<string>
  handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  setSearchMedicalCenter?: (value: string) => void
  searchMedicalCenter?: string
  isDropdown?: boolean
  isButton?: boolean
  customClass?: string
  handleChange?: any
  handleSearch?: any
  placeHolder?: any
  onkeydown?: any
}
const SearchFilter: FC<ISearchFilter> = ({
  title,
  handleClick,
  dropDownData,
  setSearchMedicalCenter,
  searchMedicalCenter,
  isDropdown,
  isButton,
  customClass,
  handleChange,
  handleSearch,
  placeHolder,
  onkeydown,
}) => {
  return (
    <>
      <div
        className={[styles.medicalCenterSearchContainer, customClass].join(' ')}
      >
        <div>
          {/* <Search
            setSearchMedicalCenter={setSearchMedicalCenter}
            searchMedicalCenter={searchMedicalCenter}
          /> */}
          <div className={styles.searchContainer}>
            <div className={styles.inputFieldContainer}>
              <input
                type="text"
                className={styles.inputSearchContainer}
                placeholder={placeHolder ? placeHolder : 'Search'}
                onChange={handleChange}
                onKeyDown={onkeydown}
              />
              <SearchButton
                handleClick={handleSearch}
                // customClass={styles.inputSearchButton}
              />
            </div>
          </div>
        </div>
        {isDropdown && (
          <div className={styles.dropDownContainer}>
            <p className={styles.packageTitle}>Package</p>
            <DropDown
              dropdownInitialState="Select"
              dropDownData={dropDownData}
              isAllData={true}
            />
          </div>
        )}
        <div>
          {isButton && (
            <Button
              title={title}
              customClass={styles.medicalCenterButtonStyle}
              handleClick={handleClick}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default SearchFilter
