// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.medicalCenterCard_cardContainer__bWjxS {
  padding: 20px;
  margin: 0 30px 30px 0;
  border: 1px solid var(--grey6);
  border-radius: 10px;
  width: 480px;
}
.medicalCenterCard_cardContainer__bWjxS .medicalCenterCard_title__U8E3k {
  font-weight: var(--font-semibold);
  font-size: var(--font-14);
  color: var(--blue1);
  margin-bottom: 20px;
}
.medicalCenterCard_cardContainer__bWjxS .medicalCenterCard_labelFields__XZ3mq {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
}
.medicalCenterCard_cardContainer__bWjxS .medicalCenterCard_labelFields__XZ3mq .medicalCenterCard_labelText__qIo6e {
  font-weight: var(--font-semibold);
  font-size: var(--font-16);
  margin-right: 15px;
  margin-top: 10px;
}
.medicalCenterCard_cardContainer__bWjxS .medicalCenterCard_labelFields__XZ3mq .medicalCenterCard_fieldContainer__xo6Gn .medicalCenterCard_inputField__3btY- {
  background: var(--white1);
  border: 1px solid var(--grey6);
  border-radius: 6px;
  padding: 10px 15px;
  width: 230px;
}
.medicalCenterCard_cardContainer__bWjxS .medicalCenterCard_labelFields__XZ3mq:last-child {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/mobileapp-configuraion/proact-medical-center/medical-center-card/medicalCenterCard.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,qBAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;AAAF;AACE;EACE,iCAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACE;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACI;EACE,iCAAA;EACA,yBAAA;EAGA,kBAAA;EACA,gBAAA;AADN;AAIM;EACE,yBAAA;EACA,8BAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;AAFR;AAKI;EACE,gBAAA;AAHN","sourcesContent":["@import \"../../../../styles/mixin.scss\";\n.cardContainer {\n  padding: 20px;\n  margin: 0 30px 30px 0;\n  border: 1px solid var(--grey6);\n  border-radius: 10px;\n  width: 480px;\n  .title {\n    font-weight: var(--font-semibold);\n    font-size: var(--font-14);\n    color: var(--blue1);\n    margin-bottom: 20px;\n  }\n  .labelFields {\n    margin-bottom: 30px;\n    display: flex;\n    flex-direction: row;\n    // justify-content: space-between;\n    .labelText {\n      font-weight: var(--font-semibold);\n      font-size: var(--font-16);\n      // width: 115px;\n      // text-align: right;\n      margin-right: 15px;\n      margin-top: 10px;\n    }\n    .fieldContainer {\n      .inputField {\n        background: var(--white1);\n        border: 1px solid var(--grey6);\n        border-radius: 6px;\n        padding: 10px 15px;\n        width: 230px;\n      }\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `medicalCenterCard_cardContainer__bWjxS`,
	"title": `medicalCenterCard_title__U8E3k`,
	"labelFields": `medicalCenterCard_labelFields__XZ3mq`,
	"labelText": `medicalCenterCard_labelText__qIo6e`,
	"fieldContainer": `medicalCenterCard_fieldContainer__xo6Gn`,
	"inputField": `medicalCenterCard_inputField__3btY-`
};
export default ___CSS_LOADER_EXPORT___;
