import axios from "axios";
import {
  ADD_PACKAGE,
  DELETE_PACKAGE_BY_ID,
  EDIT_PACKAGE,
  GET_ALL_PACKAGE,
  GET_ALL_PACKAGE_MODULES,
  GET_PACKAGE_BY_ID,
  UPDATE_STATUS_BY_ID,
} from "../../../config/config";
import { IAPIPayload } from "../../../interfaces/apiInterface";

export const getAllPackages = (data: IAPIPayload) => {
  return axios.post(GET_ALL_PACKAGE, data);
};

export const getAllPackageModules = () => {
  return axios.post(GET_ALL_PACKAGE_MODULES);
};

export const addPackages = (data: IAPIPayload) => {
  return axios.post(ADD_PACKAGE, data);
};

export const editPackages = (data: IAPIPayload) => {
  return axios.post(EDIT_PACKAGE, data);
};

export const getPackagesByIds = (data: IAPIPayload) => {
  return axios.post(GET_PACKAGE_BY_ID, data);
};

export const deletePackageByIds = (data: IAPIPayload) => {
  return axios.post(DELETE_PACKAGE_BY_ID, data);
};

export const updateStatusByIds = (data: IAPIPayload) => {
  return axios.post(UPDATE_STATUS_BY_ID, data);
};
