import { createSlice } from "@reduxjs/toolkit";
import { IPackegeState } from "../../../interfaces/apiInterface";
import {
  addPackage,
  deletePackageById,
  editPackage,
  getAllPackage,
  getAllPackageModule,
  getPackageById,
  updatePackageStatusById,
} from "../package/packageAsyncActions";

const initialState: IPackegeState = {
  loading: false,
  packageData: [],
  packageModuleData: [],
  selectedPackage: [],
  packageInfo: {},
  error: null,
};

export const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    setActivePackageList: (state, action) => {
      const index = state.selectedPackage.findIndex(
        (pkg: any) => pkg._id === action.payload._id
      );
      if (index === -1) {
        state.selectedPackage.push(action.payload);
      } else {
        state.selectedPackage.splice(index, 1);
      }
    },
    cleanUpFunction: (state) => {
      state.packageInfo = {};
      state.selectedPackage = [];
    },
  },
  extraReducers(builder) {
    //GET ALL PACKAGE
    builder.addCase(getAllPackage.pending, (state) => {
      state.loading = true;
      state.packageData = [];
    });
    builder.addCase(getAllPackage.fulfilled, (state, action) => {
      state.loading = false;
      state.packageData = action.payload.data;
    });
    builder.addCase(getAllPackage.rejected, (state, error) => {
      state.loading = false;
      state.error = error;
    });

    // GET ALL PACKAGE MODULES
    builder.addCase(getAllPackageModule.pending, (state) => {
      state.loading = true;
      state.packageModuleData = [];
    });
    builder.addCase(getAllPackageModule.fulfilled, (state, action) => {
      state.loading = false;
      state.packageModuleData = action.payload.data;
    });
    builder.addCase(getAllPackageModule.rejected, (state, error) => {
      state.loading = false;
      state.error = error;
    });

    // ADD PACKAGE
    builder.addCase(addPackage.pending, (state) => {
      state.loading = true;
      state.packageInfo = {};
    });
    builder.addCase(addPackage.fulfilled, (state, action) => {
      state.loading = false;
      state.packageInfo = action.payload;
    });
    builder.addCase(addPackage.rejected, (state, error) => {
      state.loading = false;
      state.packageInfo = {};
      state.error = error;
    });

    // EDIT PACKAGE
    builder.addCase(editPackage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editPackage.fulfilled, (state, action) => {
      state.loading = false;
      state.packageData = action.payload;
    });
    builder.addCase(editPackage.rejected, (state, error) => {
      state.loading = false;
      state.error = error;
    });

    // GET PACKAGE BY ID
    builder.addCase(getPackageById.pending, (state) => {
      state.loading = true;
      // state.packageInfo = {};
    });
    builder.addCase(getPackageById.fulfilled, (state, action) => {
      state.loading = false;
      state.packageInfo = action.payload;
      state.selectedPackage = action.payload.module_ids;
    });
    builder.addCase(getPackageById.rejected, (state, error) => {
      state.loading = false;
      state.packageInfo = {};
      state.error = error;
    });

    // DELETE PACKAGE BY ID
    builder.addCase(deletePackageById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePackageById.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePackageById.rejected, (state, error) => {
      state.loading = false;
      state.error = error;
    });

    // UPDATE STATUS BY ID
    builder.addCase(updatePackageStatusById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePackageStatusById.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updatePackageStatusById.rejected, (state, error) => {
      state.loading = false;
      state.error = error;
    });
  },
});
export const { setActivePackageList, cleanUpFunction } = packageSlice.actions;
export default packageSlice.reducer;
