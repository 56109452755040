import * as React from 'react'
import { NEWS_DESCRIPTION, NEWS_TITLE } from '../../../constants/constant'
import { proactNewsValidators } from '../../../form-validators/proactNewsValidators'
import styles from './proactNewsCard.module.scss'

interface IProactNewsCard {
  errors?: any
  register?: any
  item?: any
  index?: any
}
const ProactNewsCard: React.FunctionComponent<IProactNewsCard> = ({
  errors,
  register,
  item,
  index,
}) => {
  const handleChange = (e: any) => {
    const value = e.target.value
    if (value.length === 1 && value === ' ') {
      e.target.value = ''
    } else if (
      value.length > 1 &&
      value[0] === ' ' &&
      value[value.length - 1] === ' '
    ) {
      e.target.value = value.trim()
    }
  }
  return (
    <>
      <div className={styles.mainContainer}>
        <p className={styles.title}>News {index + 1}</p>
        <div className={styles.formMainContainer}>
          <div className={styles.formContainer}>
            <div className={styles.inputFieldContainer}>
              <label
                htmlFor={`${item?._id}.${NEWS_TITLE}`}
                className={styles.labelStyle}
              >
                News Title
              </label>
              <input
                type="text"
                name="title"
                placeholder="Enter news title"
                className={styles.inputFieldStyle}
                {...register(`${item?._id}.${NEWS_TITLE}`)}
                onChange={(e) => {
                  handleChange(e)
                }}
              />
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.inputFieldContainer}>
              <label
                htmlFor={`${item?._id}.${NEWS_DESCRIPTION}`}
                className={styles.labelDescriptionStyle}
              >
                Description
              </label>
              <textarea
                type="text"
                name="description"
                placeholder="Enter news description"
                className={styles.inputFieldDescriptionStyle}
                {...register(`${item?._id}.${NEWS_DESCRIPTION}`)}
                onChange={(e) => {
                  handleChange(e)
                }}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProactNewsCard
