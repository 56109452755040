import { createSlice } from "@reduxjs/toolkit";
import {
  addDataBaseUri,
  awsConnectionAction,
  getAllMedicalCenterUtility,
  updateConfigData,
} from "./medicalCenterUtilityAsynActions";
import { IGetAllMedicalUtylitySlice } from "../../../interfaces/apiInterface";

const initialState: IGetAllMedicalUtylitySlice = {
  isLoading: false,
  proactMedicalCenterUtility: [],
  addDataBaseUri: {},
  awsConnection: {},
  error: null,
  setAllDATA: {},
  setURIDATA: {},
  updateConfig: {},
};
export const getAllMedicalUtylitySlice = createSlice({
  name: "getAllMedicalUtylity",
  initialState,
  reducers: {
    setURIData: (state, action) => {
      console.log("action.payload :>> ", action.payload);
      state.setURIDATA = action.payload;
    },
    setAllData: (state, action) => {
      state.setAllDATA = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get dropdown data
    builder
      .addCase(getAllMedicalCenterUtility.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllMedicalCenterUtility.fulfilled, (state, action) => {
        state.isLoading = false;
        state.proactMedicalCenterUtility = action?.payload;
        // console.log('action.payload :>> ', action.payload);
      })
      .addCase(getAllMedicalCenterUtility.rejected, (state, error) => {
        state.isLoading = false;
        state.error = error;
      });

    // add database uri
    builder
      .addCase(addDataBaseUri.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addDataBaseUri.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addDataBaseUri = action?.payload;
      })
      .addCase(addDataBaseUri.rejected, (state, error) => {
        state.isLoading = false;
        state.error = error;
      });

    // aws connection
    builder
      .addCase(awsConnectionAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(awsConnectionAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.awsConnection = action?.payload;
      })
      .addCase(awsConnectionAction.rejected, (state, error) => {
        state.isLoading = false;
        state.error = error;
      });

    // update config
    builder
      .addCase(updateConfigData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateConfigData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateConfig = action?.payload;
        console.log('action.payload :>> ', action.payload);
      })
      .addCase(updateConfigData.rejected, (state, error) => {
        state.isLoading = false;
        state.error = error;
      });
  },
});

export const { setAllData, setURIData } = getAllMedicalUtylitySlice.actions;
export default getAllMedicalUtylitySlice.reducer;
