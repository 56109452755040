import { FC, useEffect, useState } from 'react'
import styles from './packageList.module.scss'
import { useNavigate } from 'react-router-dom'
import Table from '../../../components/common/table/Table'
import { packageHeaderData } from '../../../constants/data'
import SearchFilter from '../../../components/common/search-filter/SearchFilter'
import Pagination from '../../../components/common/pagination/Pagination'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import NotesPopup from '../../../components/common/modal/notes-popup/NotesPopup'
import Popup from '../../../components/common/popup/Popup'
import {
  getAllPackage,
  getPackageById,
  updatePackageStatusById,
} from '../../../redux/features/package/packageAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import DetailsPopup from '../../../components/common/modal/details-popup/DetailsPopup'
import { cleanUpFunction } from '../../../redux/features/package/packageSlice'
import Loader from '../../../components/common/spinner/Loader'
import { trimValue } from '../../../utils/utils'

interface IAppProps {}

const PacakgeList: FC<IAppProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { packageData, loading } = useAppSelector((state) => state.package)
  /* Pagination Dependency */
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  /* Pagination Dependency */
  const [show, setShow] = useState<boolean>(false)
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [searchPackage, setSearchPackage] = useState<string>('')
  const [notesData, setNotesData] = useState({})
  const [toggle, setToggle] = useState<boolean>(true)
  const [toogleValue, setToggleValue] = useState()
  const [detailsData, setDetailsData] = useState({})

  const handleModalClose = () => {
    setShow(false)
    setShowDetails(false)
  }

  const handleNotes = (item: any) => {
    setNotesData(item)
    setShow(!show)
  }

  const handleDetails = (item: any) => {
    setShowDetails(!showDetails)
    let data = {
      id: item,
    }
    dispatch(getPackageById(requestGenerator(data))).then((e) => {
      if (e.type === 'package/getPackageById/fulfilled') {
        setDetailsData(e?.payload)
      }
    })
  }

  const handleNavigate = (item: any) => {
    navigate('managepackage', {
      state: { id: item },
    })
  }

  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  useEffect(() => {
    let requestData = {
      search: searchPackage,
      pkg: '',
      page: pageIndex,
      pageSize: dataPerPage,
    }
    dispatch(getAllPackage(requestGenerator(requestData))).then((result) => {
      setTotalPage(result.payload.lastPage)
      setTotalData(result.payload.total)
      setDataPerPage(result.payload.perPage)
    })
  }, [pageIndex, dataPerPage])

  // useEffect(() => {
  //   if (searchPackage === "") {
  //     let requestData = {
  //       search: "",
  //       pkg: "",
  //       page: pageIndex,
  //       pageSize: dataPerPage,
  //     };

  //     dispatch(getAllPackage(requestGenerator(requestData))).then((result) =>
  //       setTotalPage(result.payload.lastPage)
  //     );
  //   }
  // }, [searchPackage]);

  const handleSearch = () => {
    setPageIndex(1)
    if (searchPackage.trim()?.length > 0) {
      let requestData = {
        search: searchPackage,
        pkg: '',
        page: 1,
        pageSize: dataPerPage,
      }
      dispatch(getAllPackage(requestGenerator(requestData))).then((result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      })
    }
  }

  const handleActivePackage = (item: any) => {
    if (item?.is_standard_package === false) {
      setToggle(!toggle)
      setToggleValue(item?._id)
      let activePayload = {
        id: item?._id,
        data: {
          is_active: !item?.is_active,
        },
      }
      let requestData = {
        search: '',
        pkg: '',
        page: pageIndex,
        pageSize: dataPerPage,
      }
      dispatch(updatePackageStatusById(requestGenerator(activePayload))).then(
        (e) => {
          if (e.type === 'package/updateStatusPackageById/fulfilled') {
            dispatch(getAllPackage(requestGenerator(requestData)))
          }
        }
      )
    } else {
      return
    }
  }

  return (
    <>
      {loading && <Loader />}
      {show && (
        <Popup
          Children={NotesPopup}
          popData={notesData}
          handleClose={() => handleModalClose()}
        />
      )}
      {showDetails && (
        <Popup
          Children={DetailsPopup}
          handleClose={() => handleModalClose()}
          popData={detailsData}
        />
      )}
      <div className={styles.mainContainer}>
        <SearchFilter
          title="Add Package"
          placeHolder="Search by package name"
          isButton={true}
          customClass={styles.searchFilterContainer}
          dropDownData={packageData}
          onkeydown={(e: any) => {
            if (e?.key === 'Enter') {
              handleSearch()
            }
          }}
          handleChange={(e: any) => {
            trimValue(e)
            setSearchPackage(e.target.value)
            if (e.target.value === '') {
              setPageIndex(1)
              setDataPerPage(10)
              let requestData = {
                search: '',
                pkg: '',
                page: 1,
                pageSize: 10,
              }
              dispatch(getAllPackage(requestGenerator(requestData))).then(
                (result) => {
                  setTotalPage(result.payload.lastPage)
                  setTotalData(result.payload.total)
                }
              )
            }
          }}
          handleSearch={() => handleSearch()}
          // searchMedicalCenter={searchPackage}
          // setSearchMedicalCenter={setSearchPackage}
          handleClick={() => {
            navigate('managepackage')
            return dispatch(cleanUpFunction())
          }}
        />
        <div style={{ marginBottom: '30px' }}>
          <Table
            tableHeaderData={packageHeaderData}
            tableRowData={packageData}
            handleAction={handleNavigate}
            handleNotes={handleNotes}
            handleDetails={handleDetails}
            toogleValue={toogleValue}
            handleActiveMC={handleActivePackage}
          />
        </div>
        {totalData > 10 && (
          <Pagination
            pageSize={dataPerPage}
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        )}
      </div>
    </>
  )
}

export default PacakgeList
