import {
  CREATE_MEDICAL_CENTER,
  CREATE_PROACT_NEWS,
  GET_ALL_PROACT_NEWS,
  GET_DOCTOR_TYPE,
  GET_MEDICAL_CENTER,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import { CREATE_DOCTOR_TYPE } from '../../../constants/asyncActionsType'

import {
  createMedicalCenter,
  createNews,
  getMedicalCenter,
  getNews,
  createProactDoctorConfig,
  getProactDoctorConfig,
} from './proactMobileAppCrud'

export const createProactNews = createAsyncThunkForSlice(
  CREATE_PROACT_NEWS,
  createNews,
  { isToast: true }
)

export const getAllProactNews = createAsyncThunkForSlice(
  GET_ALL_PROACT_NEWS,
  getNews
)

export const createMedicalCenters = createAsyncThunkForSlice(
  CREATE_MEDICAL_CENTER,
  createMedicalCenter,
  { isToast: true }
)

export const getAllProactMedicalCenter = createAsyncThunkForSlice(
  GET_MEDICAL_CENTER,
  getMedicalCenter
)

// doctor config
export const createDoctors = createAsyncThunkForSlice(
  CREATE_DOCTOR_TYPE,
  createProactDoctorConfig,
  { isToast: true }
)
export const getDoctors = createAsyncThunkForSlice(
  GET_DOCTOR_TYPE,
  getProactDoctorConfig
)
