// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.proactMedicalCenter_meedicalCenterContainer__jWnyP {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin: 0 20px 50px 0;
}
.proactMedicalCenter_meedicalCenterContainer__jWnyP .proactMedicalCenter_title__iq6zq {
  font-weight: var(--font-semibold);
  font-size: var(--font-18);
  margin-bottom: 20px;
}
.proactMedicalCenter_meedicalCenterContainer__jWnyP .proactMedicalCenter_cardsContainer__K51Br {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.proactMedicalCenter_meedicalCenterContainer__jWnyP .proactMedicalCenter_buttonContainer__E0gI0 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.proactMedicalCenter_meedicalCenterContainer__jWnyP .proactMedicalCenter_buttonContainer__E0gI0 .proactMedicalCenter_resetBtn__7g-0c {
  margin-right: 20px;
  color: var(--blue1);
  border: 1px solid #0e26a3;
  border-radius: 6px;
  background: var(--white1);
}`, "",{"version":3,"sources":["webpack://./src/pages/mobileapp-configuraion/proact-medical-center/proactMedicalCenter.module.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,mDAAA;EACA,mBAAA;EACA,aAAA;EACA,qBAAA;AACF;AAAE;EACE,iCAAA;EACA,yBAAA;EACA,mBAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAEJ;AADI;EACE,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,yBAAA;AAGN","sourcesContent":[".meedicalCenterContainer {\n  background: rgba(255, 255, 255, 0.7);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: 20px;\n  margin: 0 20px 50px 0;\n  .title {\n    font-weight: var(--font-semibold);\n    font-size: var(--font-18);\n    margin-bottom: 20px;\n  }\n  .cardsContainer {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n  }\n  .buttonContainer {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    margin-top: 20px;\n    .resetBtn {\n      margin-right: 20px;\n      color: var(--blue1);\n      border: 1px solid #0e26a3;\n      border-radius: 6px;\n      background: var(--white1);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"meedicalCenterContainer": `proactMedicalCenter_meedicalCenterContainer__jWnyP`,
	"title": `proactMedicalCenter_title__iq6zq`,
	"cardsContainer": `proactMedicalCenter_cardsContainer__K51Br`,
	"buttonContainer": `proactMedicalCenter_buttonContainer__E0gI0`,
	"resetBtn": `proactMedicalCenter_resetBtn__7g-0c`
};
export default ___CSS_LOADER_EXPORT___;
