import React from "react";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import styles from "./tabs.module.scss";

interface ITab {
  tabData: any;
}

const Tabs: FC<ITab> = ({ tabData }) => {
  return (
    <>
      <div className={styles.tabData}>
        {tabData.map((item: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <NavLink
                to={item.navigate}
                className={({ isActive }) =>
                  isActive ? styles.activeTab : styles.tabContent
                }
              >
                <span className={styles.tabTitle}>{item.name}</span>
              </NavLink>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default Tabs;
