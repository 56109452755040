import React from "react";
import { FC } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./sidebar.module.scss";
import proactLogo from "../../../assets/images/proactLogo.png";
import proactlatestlogo from "../../../assets/images/proactlatestlogo.png"

import { useAppSelector } from "../../../hooks";
import { colors } from "../../../constants/color";
import { sidebarData } from "../../../constants/data";

const SideBar: FC = () => {
  const navigate = useNavigate();
  const { userData } = useAppSelector((state) => state.login);
  // console.log("userData?.role", userData?.role);
  const handleNavigate = () => {
    userData?.role === "SUPER_ADMIN" && navigate("/medicalcenter");
  };
  return (
    <>
      <div className={styles.sidebarContainer}>
        <img
          src={proactlatestlogo}
          alt="proact_logo"
          className={styles.logoStyle}
          onClick={() => handleNavigate()}
        />
        <div className={styles.sidebarData}>
          {sidebarData.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <NavLink
                  to={item.navigate}
                  className={({ isActive }) =>
                    isActive ? styles.active : styles.sidebarContent
                  }
                  children={({ isActive }) => {
                    return (
                      <>
                        <div className={styles.iconTooltip}>
                          <item.icon
                            fillColor={isActive ? colors.blue1 : colors.grey1}
                          />
                          <p className={styles.iconTooltipText}>{item.name}</p>
                        </div>
                      </>
                    );
                  }}
                ></NavLink>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SideBar;
