import { FC, useState, useEffect } from 'react'
import styles from './managePackages.module.scss'
import Button from '../../../components/common/button/Button'
import {
  CheckIcon,
  UncheckIcon,
} from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import { SubmitHandler, useForm } from 'react-hook-form'
import { addPackageValidators } from '../../../form-validators/addPackageValidators'
import {
  DESCRIPTION,
  PACKAGE_NAME,
  USER_LIMIT,
  VALIDITY_DAYS,
} from '../../../constants/constant'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  addPackage,
  editPackage,
  getAllPackage,
  getAllPackageModule,
  getPackageById,
} from '../../../redux/features/package/packageAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { useLocation, useNavigate } from 'react-router-dom'
import Loader from '../../../components/common/spinner/Loader'
import {
  cleanUpFunction,
  setActivePackageList,
} from '../../../redux/features/package/packageSlice'
import { trimValue } from '../../../utils/utils'

interface IAddPackage {
  name: string
  description?: string
  module_ids: any
  selectedItems?: any
}

const ManagePackages: FC = () => {
  const [selectedItems, setSelectedItems] = useState<any>([])
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const { loading, packageModuleData, packageInfo, selectedPackage } =
    useAppSelector((state) => state.package)

  useEffect(() => {
    let data = location.state
    location &&
      location.state &&
      location.state.id &&
      dispatch(getPackageById(requestGenerator(data)))
  }, [])

  const values = packageInfo && packageInfo

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IAddPackage>({
    defaultValues: {
      name: packageInfo && packageInfo.name,
      description: packageInfo.description,
      module_ids: packageInfo.module_ids,
    },
    values,
  })

  const onSubmit: SubmitHandler<IAddPackage> = (data) => {
    let requestData = {
      search: '',
      pkg: '',
      page: pageIndex,
      pageSize: dataPerPage,
    }
    setValue('selectedItems', selectedItems)
    data.module_ids = selectedPackage
    if (location && location.state && location.state.id) {
      let id = location.state.id
      data.module_ids = selectedPackage
      dispatch(editPackage(requestGenerator({ id, data }))).then((e) => {
        if (e.type === 'package/editPackage/fulfilled') {
          navigate('/package')
          dispatch(getAllPackage(requestGenerator(requestData)))
        }
      })
    } else {
      data.module_ids = selectedPackage
      dispatch(addPackage(requestGenerator(data))).then((e) => {
        console.log('event', e)
        if (e.type === 'package/addPackage/fulfilled') {
          navigate('/package')
        }
      })
    }
    // setValue("selectedItems", selectedItems);
    // data.module_ids = selectedPackage;
    // if (location && location.state && location.state.id) {
    //   let id = location.state.id;
    //   // data.module_ids = selectedPackage.concat(selectedItems);
    //   data.module_ids = selectedPackage;
    //   dispatch(editPackage(requestGenerator({ id, data }))).then((e) => {
    //     if (e.type === "package/editPackage/fulfilled") {
    //       dispatch(getAllPackage(requestGenerator(requestData)));
    //       navigate("/package");
    //     }
    //   });
    // } else {
    //   data.module_ids = selectedPackage;
    //   dispatch(addPackage(requestGenerator(data))).then((e) => {
    //     if (e.type === "package/addPackage/fulfilled") {
    //       navigate("/package");
    //     }
    //   });
    // }
  }

  useEffect(() => {
    dispatch(getAllPackageModule({}))
  }, [])

  const handleClick = (option: any) => {
    dispatch(setActivePackageList(option))
  }

  const handleBack = (e: any) => {
    e.preventDefault()
    navigate('/package')
  }

  const handleReset = () => {
    dispatch(cleanUpFunction())
  }

  useEffect(() => {
    if (packageInfo._id) {
      setSelectedItems(packageInfo.module_ids)
      let data = location.state
      dispatch(getPackageById(requestGenerator(data)))
    }
  }, [selectedItems])

  return (
    <>
      {loading && <Loader />}
      <form
        className={styles.managePackageContainer}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.packageInfo}>
          <p className={styles.title}>Package Information</p>
          <div className={styles.fieldLabelContainer}>
            <div className={styles.fieldsMain}>
              <label className={styles.fieldLabel}>
                Name <span className="asterick">*</span>
              </label>
              <div className={styles.fieldContainer}>
                <input
                  type="text"
                  className={styles.fieldStyle}
                  placeholder="Enter package name"
                  {...register(
                    PACKAGE_NAME,
                    addPackageValidators[PACKAGE_NAME]
                  )}
                  maxLength={30}
                  onChange={(e) => trimValue(e)}
                />
                <p className={styles.errorText}>
                  {errors[PACKAGE_NAME] && (
                    <span className="error">
                      {errors[PACKAGE_NAME].message}
                    </span>
                  )}
                </p>
              </div>
            </div>
            {/* <div className={styles.fieldsMain}>
              <label className={styles.fieldLabel}>User Limit</label>
              <div className={styles.fieldContainer}>
                <input
                  type="text"
                  className={styles.fieldStyle}
                  {...register(USER_LIMIT, addPackageValidators[USER_LIMIT])}
                />
                <p className={styles.errorText}>
                  {errors[USER_LIMIT] && (
                    <span className="error">{errors[USER_LIMIT].message}</span>
                  )}
                </p>
              </div>
            </div>
            <div className={styles.fieldsMain}>
              <label className={styles.fieldLabel}>Validity (Days)</label>
              <div className={styles.fieldContainer}>
                <input
                  type="text"
                  className={styles.fieldStyle}
                  {...register(
                    VALIDITY_DAYS,
                    addPackageValidators[VALIDITY_DAYS]
                  )}
                />
                <p className={styles.errorText}>
                  {errors[VALIDITY_DAYS] && (
                    <span className="error">
                      {errors[VALIDITY_DAYS].message}
                    </span>
                  )}
                </p>
              </div>
            </div> */}
          </div>
          <div className={styles.fieldLabelContainer}>
            <div className={styles.fieldsMain}>
              <label htmlFor={DESCRIPTION} className={styles.fieldLabel}>
                Notes
              </label>
              <div className={styles.fieldContainer}>
                <textarea
                  // type="text"
                  placeholder="Enter notes"
                  className={styles.notesFieldStyle}
                  {...register(DESCRIPTION)}
                  onChange={(e) => trimValue(e)}
                />
                {/* <p className={styles.errorText}>
                  {errors[DESCRIPTION] && (
                    <span className="error">{errors[DESCRIPTION].message}</span>
                  )}
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.packageModule}>
          <p className={styles.subTitle}>Package Modules</p>
          <div className={styles.modulesContainer}>
            {packageModuleData &&
              packageModuleData.length > 0 &&
              packageModuleData.map((item: any, index: number) => {
                return (
                  <div className={styles.selectModule} key={index}>
                    <p className={styles.packageName}>{item.name}</p>
                    <span
                      className={styles.spanContainer}
                      onClick={() => handleClick(item)}
                    >
                      {selectedPackage.find(
                        ({ name }: any) => name === item.name
                      ) ? (
                        <CheckIcon fillColor={colors.green1} />
                      ) : (
                        <UncheckIcon fillColor={colors.grey2} />
                      )}
                    </span>
                  </div>
                )
              })}
          </div>
          <div className={styles.btnContainer}>
            <Button title="Submit" type="submit" />
            <Button
              title="Reset"
              type="reset"
              customClass={styles.resetBtn}
              handleClick={() => handleReset()}
            />

            <Button
              title="Back"
              customClass={styles.resetBtn}
              handleClick={(e) => handleBack(e)}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default ManagePackages
