import { FC, useRef, useState, useEffect } from 'react'
import { colors } from '../../../constants/color'
import styles from './attachfiles.module.scss'
import { Attachments, CrossIcon } from '../svg-components/index'
import { getDataUriFileSize, handleRefClick } from '../../../utils/utils'
import { fileType } from '../../../interfaces/interfaces'
import { UseFormSetValue } from 'react-hook-form'
import { dataURI } from '../../../utils/utils'

interface IAttachFiles {
  fileKey: any
  error?: string
  file: any
  setFile: any
  setValue: UseFormSetValue<any>
  isMultiSelect?: boolean
  defaultAttachments: fileType[]
  attachments: any
  setAttachments: any
  customClass?: string
  setErrors?: any
  clearErrors?: any
}

const AttachFiles: FC<IAttachFiles> = ({
  fileKey,
  error,
  file,
  setFile,
  setValue,
  isMultiSelect,
  attachments,
  setAttachments,
  setErrors,
  defaultAttachments,
  customClass,
  clearErrors,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  // const [attachments, setAttachments] = useState<fileType[]>([])
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log('event>>', event.target.files)
    if (event.target.files) {
      const newAttachments = [...defaultAttachments]
      for (let i = 0; i < event.target.files.length; i++) {
        const file: any = event.target.files[i]
        console.log('file size', file)
        if (file?.size >= 2 * 1024 * 1024) {
          setErrors(fileKey, {
            type: 'custom',
            message: 'Maximum attachments size is 2MB',
          })
        }

        const getDataURI = await dataURI(file)
        newAttachments.push({
          name: file.name,
          data_uri: getDataURI,
        })
      }
      setAttachments(newAttachments)
      if (setValue && fileKey && newAttachments) {
        setValue(fileKey, newAttachments)
      }
    }
  }
  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }
  const handleRemoveAttachment = (index: number) => {
    // clearErrors(fileKey)
    const newAttachments = [...attachments]
    newAttachments.splice(index, 1)
    resetFileInput() // reset the file input value

    setAttachments(newAttachments)
    if (setValue && fileKey && newAttachments) {
      setValue(fileKey, newAttachments)
    }
  }

  const handleChange = (e: any) => {
    let file = e.target.files[0].name
    setFile(file)
    if (setValue && fileKey && file) {
      setValue(fileKey, file)
    }
  }
  console.log('default', defaultAttachments)
  useEffect(() => {
    setValue(fileKey, defaultAttachments || [])
  }, [defaultAttachments])
  useEffect(() => {
    const bigSizeFiles =
      defaultAttachments &&
      defaultAttachments.length > 0 &&
      defaultAttachments.find(
        (item: any) => getDataUriFileSize(item.data_uri) >= 2 * 1024 * 1024
      )
    if (bigSizeFiles) {
      setErrors(fileKey, {
        type: 'custom',
        message: 'Maximum attachments size is 2MB',
      })
    } else {
      clearErrors(fileKey)
    }
  }, [defaultAttachments])
  return (
    <div className={styles.attachmentsContainer}>
      <div className={styles.attachmentsIconListContainer}>
        <span onClick={() => handleRefClick(fileInputRef)}>
          <input
            type="file"
            ref={fileInputRef}
            accept="image/png, image/jpeg, image/jpg, image/svg+xml, application/pdf, application/msword, text/xml"
            onChange={isMultiSelect ? handleFileChange : handleChange}
            style={{ display: 'none' }}
          />
          <Attachments
            fillColor={colors.white1}
            fillColor1={colors.green1}
            customClass={styles.attchmentsIcon}
          />
        </span>
        <div className={styles.attachedFileListsContainer}>
          {/* {!isMultiSelect && <p>{file}</p>} */}
          {defaultAttachments &&
            defaultAttachments.length > 0 &&
            defaultAttachments.map((item: fileType, index: number) => {
              return (
                <div className={styles.attachedFile} key={index}>
                  <span
                    className={[styles.attachedFileName, customClass].join(' ')}
                  >
                    {item.name}
                  </span>
                  <span
                    onClick={() => handleRemoveAttachment(index)}
                    className={styles.crossIcon}
                  >
                    <CrossIcon
                      fillColor={colors.white1}
                      fillColor1={colors.red1}
                    />
                  </span>
                </div>
              )
            })}
        </div>
      </div>
      <p className="dashboardFormError">{error}</p>
    </div>
  )
}

export default AttachFiles
